import React, { ReactNode } from 'react';
import { MenuItem as MuiMenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMenuItem = styled(MuiMenuItem)({
  padding: '12px 24px',
  color: '#94a3b8', // $text-secondary
  fontSize: '1rem',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // $nav-active-bg
    color: '#ffffff', // $text-primary
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)', // $nav-active-bg
    color: '#ffffff', // $text-primary
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },
  },
});

interface MenuItemProps {
  onClick?: () => void;
  selected?: boolean;
  children: ReactNode;
  className?: string;
}

function MenuItem(props: MenuItemProps) {
  return <StyledMenuItem {...props}>{props.children}</StyledMenuItem>;
}

export default MenuItem;
