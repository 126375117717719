import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { Chip, TableHead } from '@mui/material';
import './legend.scss';
import Grid from '../grid/grid';
import Tooltip from '../tooltip/tooltip';

// TODO: move this to features and create table components

interface LegendProps {
  rows: LegendRowProps[];
  className?: string;
}

export interface LegendRowProps {
  color: string;
  label: string;
  value: string | number;
  additionalValue: string | number;
}

function YearlyExpenseLegend(props: LegendProps) {
  return (
    <TableContainer
      sx={{
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
          width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.1)',
          borderRadius: '20px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgba(0,0,0,.2)',
        },
      }}
      className={`${props.className}`}
    >
      <Table
        stickyHeader
        aria-label="simple table"
        // className="table-dark-theme text-input-primary"
      >
        <TableHead>
          <TableRow>
            <TableCell>
              <Grid container>
                <Grid item xs={4}>
                  Expense
                </Grid>
                <Grid item xs={4} align="right">
                  $/year
                </Grid>
                <Grid item xs={4} align="right">
                  $/month
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map((row) => (
            <TableRow
              key={row.label}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                <Grid container>
                  <Grid item xs={6}>
                    <Tooltip title={row.label} placement="right-start">
                      <Chip
                        label={row.label}
                        style={{ backgroundColor: row.color }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={3} align="right">
                    {row.value}
                  </Grid>
                  <Grid item xs={3} align="right">
                    {row.additionalValue}
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default YearlyExpenseLegend;
