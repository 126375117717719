import React, { Ref, forwardRef, useState } from 'react';
import AppBar from '../../components/app-bar/app-bar';
import Box from '../../components/box/box';
import Toolbar from '../../components/toolbar/toolbar';
import Typography, {
  TypographyVariant,
} from '../../components/typography/typography';
import Button from '../../components/button/button';
import IconButton from '../../components/icons/icon-button';
import Grid from '../../components/grid/grid';
import Icon, { IconType } from '../../components/icons/icon';
import { routes } from '../../routes/routes';
import { useNavigate, useLocation } from 'react-router-dom';
import './top-nav-bar.scss';
import { costGuideUrl, landingPageUrl } from '../../constants';
import Menu from '../../components/menu/menu';
import MenuItem from '../../components/menu/menu-item';

interface TopNavBarProps {
  setMaxWidth?: boolean;
}

const TopNavBar = forwardRef(function TopNavBar(
  props: TopNavBarProps,
  ref?: Ref<HTMLInputElement>
) {
  const [mobileMenuAnchor, setMobileMenuAnchor] = useState<null | HTMLElement>(
    null
  );
  const navigate = useNavigate();
  const location = useLocation();

  // Helper function to check if a route is currently active
  const isRouteActive = (route: string) => {
    return location.pathname.endsWith(route);
  };

  const handleBuyersClick = () => {
    navigate('..' + routes.buyerDashboard);
  };

  const handleInvestorsClick = () => {
    navigate('..' + routes.investorDashboard);
  };

  const handleHomeClick = () => {
    navigate('..' + routes.home);
  };

  const handleAboutClick = () => {
    navigate('..' + routes.about);
  };

  const handleContactClick = () => {
    navigate('..' + routes.contact);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMenuAnchor(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMobileMenuAnchor(null);
  };

  const handleMenuItemClick = (route: string) => {
    navigate('..' + route);
    handleMenuClose();
  };

  const navigationItems = [
    { text: 'About', route: routes.about },
    { text: 'Contact', route: routes.contact },
    { text: 'Buyers', route: routes.buyerDashboard },
    { text: 'Investors', route: routes.investorDashboard },
  ];

  return (
    <Grid
      ref={ref}
      item
      xs={12}
      style={{
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
      className="bg-dark-secondary border-bottom-light"
    >
      {/* Animated background glow */}
      <div
        className="animated-glow"
        style={{ position: 'absolute', inset: 0, opacity: 0.05 }}
      />

      <Box
        style={{
          flexGrow: 1,
          maxWidth: props.setMaxWidth ? '1400px' : '',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <AppBar
          position="static"
          className="bg-dark-secondary"
          style={{
            boxShadow: 'none',
            padding: '16px 0',
          }}
        >
          <Toolbar>
            {/* Logo */}
            <div
              className="flex-center"
              style={{ marginRight: '16px', cursor: 'pointer' }}
              onClick={handleHomeClick}
            >
              <div className="accent-badge">
                <Icon
                  iconType={IconType.HouseCircleCheck}
                  style={{ height: '24px', width: '24px' }}
                  className="text-primary"
                />
              </div>
            </div>
            <div style={{ flexGrow: 1 }}>
              <Typography
                variant={TypographyVariant.Subtitle1}
                style={{
                  cursor: 'pointer',
                  fontSize: '1.5rem',
                  fontWeight: 600,
                  display: 'inline-block',
                }}
                onClick={handleHomeClick}
                className="text-primary"
              >
                PROP COST
              </Typography>
            </div>

            {/* Desktop Navigation */}
            <div className="hide-on-mobile">
              {navigationItems.map((link) => (
                <Button
                  key={link.text}
                  variant="text"
                  className={
                    isRouteActive(link.route)
                      ? 'nav-button active'
                      : 'nav-button'
                  }
                  onClick={() => navigate('..' + link.route)}
                  style={{ marginLeft: '16px' }}
                >
                  {link.text}
                </Button>
              ))}
            </div>

            {/* Mobile Menu Button */}
            <div className="show-on-mobile">
              <IconButton
                onClick={handleMenuOpen}
                style={{ marginLeft: '8px' }}
                className="text-primary"
              >
                <Icon iconType={IconType.Menu} />
              </IconButton>
            </div>

            {/* Mobile Menu */}
            <Menu
              anchorEl={mobileMenuAnchor}
              open={Boolean(mobileMenuAnchor)}
              onClose={handleMenuClose}
            >
              {navigationItems.map((item) => (
                <MenuItem
                  key={item.text}
                  onClick={() => handleMenuItemClick(item.route)}
                  selected={isRouteActive(item.route)}
                >
                  {item.text}
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
      </Box>
    </Grid>
  );
});

export default TopNavBar;
