import React, { useEffect, useMemo, useState } from 'react';
import InputForm from '../../features/dashboard/input-form/input-form';
import {
  useBuyerEstimateInputQuery,
  useInvestorEstimateInputQuery,
} from '../../api/queries/EstimateInputQueries';
import { EstimateInput } from '../../api/models/EstimateInput';
import LoadingContainer from '../../components/loading-container/loading-container';
import Grid from '../../components/grid/grid';
import { useEstimateOutputMutation } from '../../api/mutations/EstimateOutput';
import { EstimateOutput } from '../../api/models/EstimateOutput';
import EstimateResults from '../../features/dashboard/estimate-results';
import LoadWasm from '../../wasm/load-wasm';
import { get } from 'http';
import { Link } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { Tooltip } from '@mui/material';

interface DashboardProps {
  height: string;
  viewType: DashboardViewType;
}

export enum DashboardViewType {
  Buyer = 'buyer',
  Investor = 'investor',
}

function Dashboard({ height, viewType }: DashboardProps) {
  const [estimateOutput, setEstimateOutput] = useState<EstimateOutput>();
  const [estimateInput, setEstimateInput] = useState<EstimateInput>();

  // api calls
  const { data: buyerInputs, isLoading: buyerInputsLoading } =
    useBuyerEstimateInputQuery();
  const { data: investorInputs, isLoading: investorInputsLoading } =
    useInvestorEstimateInputQuery();

  useEffect(() => {
    if (buyerInputs && investorInputs) {
      if (viewType === DashboardViewType.Investor) {
        setEstimateInput(investorInputs);
        getEstimateOutput(investorInputs);
      } else {
        setEstimateInput(buyerInputs);
        getEstimateOutput(buyerInputs);
      }
    }
  }, [buyerInputs, investorInputs, viewType]);

  const getEstimateOutput = (data: EstimateInput) => {
    estimateOutputMutation.mutateAsync(data, {
      onSuccess: (estimate) => {
        setEstimateOutput(estimate);
      },
    });
  };

  const isLoading = buyerInputsLoading && investorInputsLoading;

  const estimateOutputMutation = useEstimateOutputMutation();

  const submitEstimate = (data: EstimateInput) => {
    if (viewType === DashboardViewType.Investor) {
      data.hasRentalIncome = true;
    } else {
      data.hasRentalIncome = false;
    }

    estimateOutputMutation.mutateAsync(data, {
      onSuccess: (estimate) => {
        setEstimateOutput(estimate);
      },
    });
  };

  const onSubmit = (data: EstimateInput) => {
    submitEstimate(data);
  };

  return (
    <LoadWasm>
      <LoadingContainer isLoading={isLoading}>
        <Grid container>
          {/* left side of screen (inputs) */}
          <Grid
            item
            sm={5}
            md={4}
            lg={2.5}
            style={{
              paddingTop: '2rem',
              paddingBottom: '2rem',
              paddingRight: '1rem',
              paddingLeft: '2rem',
              height: `${height}`,
            }}
          >
            {estimateInput && (
              <InputForm
                defaultInputs={estimateInput}
                onSubmit={onSubmit}
                viewType={viewType}
              />
            )}
          </Grid>

          {/* right side of screen (charts) */}
          <Grid
            item
            sm={7}
            md={8}
            lg={9.5}
            style={{
              paddingTop: '2rem',
              paddingBottom: '2rem',
              paddingRight: '2rem',
              paddingLeft: '1rem',
              flexGrow: 1,
              height: `${height}`,
            }}
          >
            {estimateOutput && (
              <EstimateResults
                estimateOutput={estimateOutput}
                viewType={viewType}
              />
            )}
          </Grid>
        </Grid>

        {/* Add this feedback FAB */}
        <Tooltip
          title="Help us improve! Share your feedback"
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'rgba(0, 0, 0, 0.87)',
                padding: '8px 12px',
                fontSize: '0.95rem',
                borderRadius: '4px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
              },
            },
          }}
          placement="left"
        >
          <Fab
            component={Link}
            to="/contact"
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 24,
              right: 24,
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          >
            <FeedbackIcon />
          </Fab>
        </Tooltip>
      </LoadingContainer>
    </LoadWasm>
  );
}

export default Dashboard;
