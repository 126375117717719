import React, { CSSProperties } from 'react';
import TextInput from '../../../components/text-input/text-input';
import DataCard from '../../../components/data-card/data-card';
import { IconType } from '../../../components/icons/icon';
import Checkbox from '../../../components/checkbox/checkbox';
import InfoIcon from '../../../components/info-icon/info-icon';
import './rent-comparison-input.scss';

interface RentComparisonProps {
  monthlyRent: number;
  setMonthlyRent: (monthlyRent: number) => void;
  inflationRate: number;
  setInflationRate: (inflationRate: number) => void;
  breakEvenYear: number;
  compareRent: boolean;
  setCompareRent: (compareRent: boolean) => void;
  style?: CSSProperties;
}

function RentComparisonInput(props: RentComparisonProps) {
  const breakEvenYearDisplay = (year: number): string => {
    if (year < 0) {
      return 'Never';
    } else {
      return year.toString();
    }
  };

  return (
    <div
      className={
        !props.compareRent ? 'mui-dark-input-disabled' : 'mui-dark-input'
      }
    >
      <div
        style={{
          ...props.style,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Checkbox
          label="Compare to Renting"
          checked={props.compareRent}
          onChange={props.setCompareRent}
          className={
            !props.compareRent
              ? 'compare-checkbox-non-active'
              : 'compare-checkbox-active'
          }
        />
        <InfoIcon
          title="Renting Comparison"
          body="Compare the accumulated cost of renting to the cost of owning a home."
          disabled={!props.compareRent}
        />
      </div>

      <TextInput
        label="Monthly Rent"
        defaultValue={props.monthlyRent}
        onChange={(e) => props.setMonthlyRent(e.target.value)}
        id="monthly-rent"
        name="monthly-rent"
        prefix="$"
        disabled={!props.compareRent}
      />

      <TextInput
        label="Inflation Rate"
        defaultValue={props.inflationRate}
        onChange={(e) => props.setInflationRate(e.target.value)}
        id="inflation-rate"
        name="inflation-rate"
        prefix="%"
        disabled={!props.compareRent}
      />

      <DataCard
        title={`Renting Break Even Year`}
        body={breakEvenYearDisplay(props.breakEvenYear)}
        iconVariant={IconType.Calendar}
        style={{ marginTop: '8px' }}
        infoTitle="Renting Break Even Year"
        infoMessage="The year when the accumulated cost of renting exceeds the net profit at sale. Renting would be more economical if you plan to sell before this year."
        disabled={!props.compareRent}
      />
    </div>
  );
}

export default RentComparisonInput;
