import React, { useMemo, useRef } from 'react';
import Paper from '../../components/paper/paper';
import { EstimateOutput } from '../../api/models/EstimateOutput';
import YearlyExpenseGainChart from './charts/yearly-expense-gain-chart';
import YearlyExpensePieChart from './charts/yearly-expense-pie-chart';
import CumulativeGainLossChart from './charts/cumulative-gain-loss-chart';
import SaleChart from './charts/sale-chart';
import { useHeightObserver } from '../../utils/resize-observer-hooks';
import YearlyGainPieChart from './charts/yearly-gain-pie-chart';
import MarketValueChart from './charts/market-value-chart';
import { DashboardViewType } from './dashboard';
import CashFlowChart from './charts/cash-flow-chart';
import OverviewChart from './charts/overview-chart';
import TabBar from '../../components/tab-bar/tab-bar';
import InvestorOverviewChart from './charts/investor-overview-chart';
import ExpenseSankeyChart from './charts/expense-sankey-chart';
import GainSankeyChart from './charts/gain-sankey-chart';

interface EstimateResultsProps {
  estimateOutput: EstimateOutput;
  viewType: DashboardViewType;
}

function EstimateResults({ estimateOutput, viewType }: EstimateResultsProps) {
  const [activeStep, setActiveStep] = React.useState(0);

  // heights
  const containerRef = useRef(null);
  const stepperRef = useRef(null);
  const containerHeight = useHeightObserver(containerRef);
  const stepperHeight = useHeightObserver(stepperRef);
  const activeChartHeight = containerHeight - stepperHeight;

  // charts
  const buyerChartComponents = useMemo(
    () => [
      {
        title: 'Overview',
        chart: (
          <OverviewChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
            setActiveStep={setActiveStep}
          />
        ),
      },
      {
        title: 'Monthly Costs',
        chart: (
          <YearlyExpenseGainChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
          />
        ),
      },
      {
        title: 'Expenses',
        chart: (
          <YearlyExpensePieChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
          />
        ),
      },
      // {
      //   title: 'Expenses',
      //   chart: (
      //     <ExpenseSankeyChart
      //       height={activeChartHeight}
      //       estimateOutput={estimateOutput}
      //     />
      //   ),
      // },
      // {
      //   title: 'Returns',
      //   chart: (
      //     <YearlyGainPieChart
      //       height={activeChartHeight}
      //       estimateOutput={estimateOutput}
      //     />
      //   ),
      // },
      {
        title: 'Returns',
        chart: (
          <GainSankeyChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
          />
        ),
      },
      {
        title: 'Profit at Sale',
        chart: (
          <SaleChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
            viewType={viewType}
          />
        ),
      },
      {
        title: 'Gain/Loss',
        chart: (
          <CumulativeGainLossChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
            viewType={viewType}
          />
        ),
      },
      {
        title: 'Property Value',
        chart: (
          <MarketValueChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
          />
        ),
      },
    ],
    [activeChartHeight, estimateOutput, viewType]
  );

  const investorChartComponents = useMemo(
    () => [
      {
        title: 'Overview',
        chart: (
          <InvestorOverviewChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
            setActiveStep={setActiveStep}
          />
        ),
      },
      {
        title: 'Profit at Sale',
        chart: (
          <SaleChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
            viewType={viewType}
          />
        ),
      },
      {
        title: 'Cash Flow',
        chart: (
          <CashFlowChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
            viewType={viewType}
          />
        ),
      },
      // {
      //   title: 'Expenses',
      //   chart: (
      //     <YearlyExpensePieChart
      //       height={activeChartHeight}
      //       estimateOutput={estimateOutput}
      //     />
      //   ),
      // },
      {
        title: 'Expenses',
        chart: (
          <ExpenseSankeyChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
          />
        ),
      },
      // {
      //   title: 'Returns',
      //   chart: (
      //     <YearlyGainPieChart
      //       height={activeChartHeight}
      //       estimateOutput={estimateOutput}
      //     />
      //   ),
      // },
      {
        title: 'Returns',
        chart: (
          <GainSankeyChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
          />
        ),
      },
      {
        title: 'Gain/Loss',
        chart: (
          <CumulativeGainLossChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
            viewType={viewType}
          />
        ),
      },
      {
        title: 'Property Value',
        chart: (
          <MarketValueChart
            height={activeChartHeight}
            estimateOutput={estimateOutput}
          />
        ),
      },
    ],
    [activeChartHeight, estimateOutput, viewType]
  );

  const tabs = (
    viewType === DashboardViewType.Investor
      ? investorChartComponents
      : buyerChartComponents
  ).map((component) => component.title);

  const chartComponents =
    viewType === DashboardViewType.Investor
      ? investorChartComponents.map((component) => component.chart)
      : buyerChartComponents.map((component) => component.chart);

  // active chart
  const activeChart = useMemo(() => {
    if (activeStep >= 0 && activeStep < chartComponents.length) {
      return chartComponents[activeStep];
    } else {
      return <div>Error</div>;
    }
  }, [activeStep, chartComponents]);

  return (
    <Paper
      elevation={3}
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid rgba(255, 255, 255, 0.2)',
      }}
      ref={containerRef}
      className="bg-dark-secondary"
    >
      <TabBar
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        tabs={tabs}
        ref={stepperRef}
        className="mui-dark-tabs"
      />

      {activeChart}
    </Paper>
  );
}

export default EstimateResults;
