import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from './pages/home/Home';
import BuyerDashboard from './pages/buyer-dashboard/buyer-dashboard';
import InvestorDashboard from './pages/investor-dashboard/investor-dashboard';
import { routes } from './routes/routes';
import Contact from './pages/contact/contact';
import About from './pages/about/about';

function App() {
  return (
    <Router>
      <Routes>
        <Route path={routes.buyerDashboard} element={<BuyerDashboard />} />
        <Route
          path={routes.investorDashboard}
          element={<InvestorDashboard />}
        />
        <Route path={routes.home} element={<Home />} />
        <Route path={routes.about} element={<About />} />
        <Route path={routes.contact} element={<Contact />} />
        <Route path="*" element={<Navigate to={routes.home} />} />
      </Routes>
    </Router>
  );
}

export default App;
