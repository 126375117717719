import React from 'react';
import {
  Switch as MuiSwitch,
  FormGroup,
  FormControlLabel,
  Typography,
  Box,
} from '@mui/material';

export interface SwitchProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
  leftLabel?: string;
  rightLabel?: string;
  className?: string;
}

const Switch = ({
  checked,
  onChange,
  style,
  leftLabel,
  rightLabel,
  className,
}: SwitchProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      style={style}
      className={`${className || ''}`}
    >
      {leftLabel && <Typography sx={{ mr: 1 }}>{leftLabel}</Typography>}
      <MuiSwitch checked={checked} onChange={onChange} />
      {rightLabel && <Typography sx={{ ml: 1 }}>{rightLabel}</Typography>}
    </Box>
  );
};

export default Switch;
