import React, { Ref, forwardRef } from 'react';
import './text-input.scss';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { Input, TextField, styled } from '@mui/material';

interface TextInputProps {
  id: string;
  label: string;
  onChange?: (...args: any) => void;
  type?: 'number' | 'text';
  prefix?: string;
  name: string;
  onBlur?: (...args: any) => void;
  hasError?: boolean;
  errorMessage?: string;
  value?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
  style?: React.CSSProperties;
}

const TextInput = forwardRef(function TextInput(
  props: TextInputProps,
  ref: Ref<HTMLInputElement>
) {
  const classNames = `${props.className}`;

  return (
    <FormControl fullWidth className={classNames} style={props.style}>
      <InputLabel htmlFor={props.id}>{props.label}</InputLabel>

      <OutlinedInput
        id={props.id}
        startAdornment={
          <InputAdornment position="start" disableTypography={true}>
            <p>{props.prefix}</p>
          </InputAdornment>
        }
        label={props.label}
        type={props.type}
        onChange={props.onChange}
        ref={ref}
        onBlur={props.onBlur}
        name={props.name}
        error={props.hasError}
        value={props.value}
        defaultValue={props.defaultValue}
        disabled={props.disabled}
        inputProps={{
          step: 'any',
          readOnly: props.readOnly,
        }}
      />

      {/* {props.hasError && (
        <FormHelperText error id="accountId-error">
          {props.errorMessage}
        </FormHelperText>
      )} */}
    </FormControl>
  );
});

export default TextInput;
