import React, { useMemo, useState } from 'react';
import LineChart, {
  CoordinateProps,
  LineProps,
} from '../../../components/line-chart/line-chart';
import { EstimateOutput } from '../../../api/models/EstimateOutput';
import {
  ChartBlue,
  ChartGreen,
  ChartRed,
} from '../../../utils/constants/color';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import Grid from '../../../components/grid/grid';
import RentComparisonInput from './rent-comparison-input';
import useRentLineHook from '../../../utils/rent-line-hook';
import useLineBreakEvenHook from '../../../utils/rental-income-break-even-hook';
import DataCard from '../../../components/data-card/data-card';
import { IconType } from '../../../components/icons/icon';
import { DashboardViewType } from '../dashboard';

interface CumulativeGainLossChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
  viewType?: DashboardViewType;
}

function CumulativeGainLossChart({
  estimateOutput,
  height,
  viewType,
}: CumulativeGainLossChartProps) {
  const [monthlyRent, setMonthlyRent] = useState(1300);
  const [inflationRate, setInflationRate] = useState(3);
  const [compareRent, setCompareRent] = useState(false);

  // loss line
  const lossLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];
    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      if (year.year === 0) {
        continue;
      }

      coords.push({ x: year.year, y: year.totalSummary.lossesTotal * -1 });
    }

    return {
      label: 'Total Loss',
      key: 'loss',
      color: ChartRed,
      coordinates: coords,
    };
  }, [estimateOutput]);

  // gain line
  const gainLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];
    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      if (year.year === 0) {
        continue;
      }

      coords.push({ x: year.year, y: year.totalSummary.gainsTotal });
    }

    return {
      label: 'Total Gain',
      key: 'gain',
      color: ChartGreen,
      coordinates: coords,
    };
  }, [estimateOutput]);

  // net line
  const netLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];
    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      if (year.year === 0) {
        continue;
      }

      coords.push({ x: year.year, y: year.totalSummary.net });
    }

    return {
      label: 'Net',
      key: 'net',
      color: ChartBlue,
      coordinates: coords,
    };
  }, [estimateOutput]);

  // rent comparison line
  const { breakEvenYear, rentLine } = useRentLineHook(
    monthlyRent,
    inflationRate,
    lossLine.coordinates.length,
    netLine
  );

  // rent break even point
  const rentalIncomeBreakEvenPoint: number =
    useLineBreakEvenHook(netLine).breakEvenYear;

  const buyerLines = compareRent
    ? [gainLine, netLine, lossLine, rentLine]
    : [gainLine, netLine, lossLine];
  const investorLines = [gainLine, netLine, lossLine];
  const lines: LineProps[] =
    viewType === DashboardViewType.Buyer ? buyerLines : investorLines;

  return (
    <Box
      style={{
        padding: '1rem',
        height: `calc(${height}px - 1rem)`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ChartHeader
        title="Cumulative Gain/Loss"
        subtitle="Accumulated gains and losses from owning the property. Selling costs are not included."
        className="text-input-primary"
      />

      <Grid
        container
        style={{
          flexGrow: 1,
          overflow: 'hidden',
          height: '100%',
          paddingTop: '1rem',
          paddingBottom: '1rem',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Grid
          item
          xs={3}
          style={{
            paddingRight: '1rem',
            overflow: 'auto',
            display: 'flex',
            height: '100%',
          }}
        >
          {viewType === DashboardViewType.Investor ? (
            <div style={{ height: '100%', width: '100%' }}>
              <DataCard
                title={`Break Even Year`}
                body={rentalIncomeBreakEvenPoint.toString()}
                iconVariant={IconType.Calendar}
                style={{ marginTop: '8px', width: '100%' }}
              />
            </div>
          ) : (
            <RentComparisonInput
              monthlyRent={monthlyRent}
              setMonthlyRent={setMonthlyRent}
              inflationRate={inflationRate}
              setInflationRate={setInflationRate}
              breakEvenYear={breakEvenYear}
              compareRent={compareRent}
              setCompareRent={setCompareRent}
            />
          )}
        </Grid>

        <Grid item xs={9} style={{ padding: '40px' }}>
          <LineChart
            lines={lines}
            xLabel="Year"
            yLabel="Dollars ($)"
            xLabelInterval={1}
            className="chart-dark-theme"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CumulativeGainLossChart;
