import React, { ReactNode } from 'react';
import { Menu as MuiMenu } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledMenu = styled(MuiMenu)({
  '& .MuiPaper-root': {
    backgroundColor: '#1a1c2a', // $dark-bg-secondary
    border: '1px solid rgba(255, 255, 255, 0.1)', // $glass-border
    borderRadius: '8px',
    minWidth: '200px',
    marginTop: '8px',
  },
});

interface MenuProps {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  className?: string;
}

function Menu(props: MenuProps) {
  return <StyledMenu {...props}>{props.children}</StyledMenu>;
}

export default Menu;
