import React from 'react';
import {
  ResponsiveContainer,
  Sankey,
  Tooltip,
  Label,
  Rectangle,
  Layer,
} from 'recharts';
import { colors } from '../../Theme';
import Typography from '../typography/typography';

function Node({ x, y, width, height, index, payload, containerWidth }: any) {
  return (
    <Layer key={`CustomNode${index}`}>
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={payload.fill || '#5da8ee'}
        fillOpacity="0.9"
      />
      <text
        textAnchor="start"
        x={x + width + 6}
        y={y + height / 2 - 8}
        fontSize="14"
        fill="#ffffff"
        className="text-primary"
        style={{
          dominantBaseline: 'middle',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 500,
        }}
      >
        {payload.name}
      </text>
      <text
        textAnchor="start"
        x={x + width + 6}
        y={y + height / 2 + 8}
        fontSize="14"
        fill="#94a3b8"
        className="text-secondary"
        style={{
          dominantBaseline: 'middle',
          fontFamily: 'Roboto, sans-serif',
          fontWeight: 400,
        }}
      >
        {`$${payload.value.toLocaleString()}`}
      </text>
    </Layer>
  );
}

export interface SankeyNode {
  name: string;
  value?: number;
  fill?: string;
}

export interface SankeyLink {
  source: number;
  target: number;
  value: number;
}

interface SankeyChartProps {
  data: {
    nodes: SankeyNode[];
    links: SankeyLink[];
  };
  height?: number | string;
  width?: number | string;
  style?: React.CSSProperties;
  className?: string;
}

interface LabelProps {
  x?: string | number;
  y?: string | number;
  width?: string | number;
  height?: string | number;
  payload?: SankeyNode;
}

function SankeyChart({
  data,
  height = '100%',
  width = '100%',
  style,
  className,
}: SankeyChartProps) {
  return (
    <div
      style={{
        width,
        height,
        backgroundColor: '#1a1c2a',
        borderRadius: '8px',
        padding: '1rem',
        ...style,
      }}
      className={`chart-dark-theme ${className || ''}`}
    >
      <ResponsiveContainer>
        <Sankey
          data={data}
          nodeWidth={20}
          nodePadding={40}
          margin={{
            left: 40,
            right: 200,
            top: 20,
            bottom: 20,
          }}
          link={{
            stroke: '#3a7ced',
            strokeOpacity: 0.2,
          }}
          node={<Node containerWidth={960} />}
        >
          <Tooltip
            contentStyle={{
              backgroundColor: '#23252f',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              borderRadius: '8px',
              padding: '12px',
            }}
            labelStyle={{
              color: '#ffffff',
            }}
            itemStyle={{
              color: '#94a3b8',
            }}
          />
        </Sankey>
      </ResponsiveContainer>
    </div>
  );
}

export default SankeyChart;
