import React, { CSSProperties, ReactNode } from 'react';
import { IconButton as MuiIconButton } from '@mui/material';

interface IconProps {
  size?: 'small' | 'medium' | 'large';
  edge?: 'start' | 'end' | false;
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  ariaLabel?: string;
  style?: CSSProperties;
  children: ReactNode;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

function IconButton(props: IconProps) {
  return <MuiIconButton {...props}>{props.children}</MuiIconButton>;
}

export default IconButton;
