import React, { useMemo } from 'react';
import LineChart, {
  CoordinateProps,
  LineProps,
} from '../../../components/line-chart/line-chart';
import { EstimateOutput } from '../../../api/models/EstimateOutput';
import {
  ChartGreen,
  ChartOrange,
  ChartRed,
} from '../../../utils/constants/color';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import Grid from '../../../components/grid/grid';
import { DashboardViewType } from '../dashboard';
import Table from '../../../components/table/table';
import { RowProps } from '../../../components/table/table';
import Switch from '../../../components/switch/switch';
import { CellConfig } from '../../../components/table/table';

interface CashFlowChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
  viewType: DashboardViewType;
}

function CashFlowChart({
  estimateOutput,
  height,
  viewType,
}: CashFlowChartProps) {
  const [isMonthly, setIsMonthly] = React.useState(false);
  const divisor = isMonthly ? 12 : 1;

  /* lines */
  const yearlyOutOfPocketLine: LineProps = useMemo(() => {
    const yearlyOutOfPocketCoords: CoordinateProps[] = [];

    yearlyOutOfPocketCoords.push({ x: 0, y: 0 });

    for (let i = 0; i < estimateOutput.years.length; i++) {
      const year = estimateOutput.years[i];

      const rentalIncome =
        year.gains.find((g) => g.subject === 'rentalIncome')?.amount || 0;

      const net = rentalIncome - year.yearSummary.lossesTotal;

      let contribution = net;

      if (i === 0) {
        contribution = 0;
      }

      yearlyOutOfPocketCoords.push({
        x: year.year,
        y: contribution / divisor,
      });
    }

    return {
      label: 'Cash Flow',
      key: 'outOfPocketCosts',
      color: ChartRed,
      coordinates: yearlyOutOfPocketCoords,
    };
  }, [estimateOutput, divisor]);

  const yearlyRentalIncomeLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];

    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      const rentalIncome =
        year.gains.find((g) => g.subject === 'rentalIncome')?.amount || 0;

      coords.push({ x: year.year, y: rentalIncome / divisor });
    }

    return {
      label: 'Rental Income',
      key: 'rentalIncome',
      color: ChartGreen,
      coordinates: coords,
    };
  }, [estimateOutput, divisor]);

  const yearlyExpenseLine: LineProps = useMemo(() => {
    const coords: CoordinateProps[] = [];

    coords.push({ x: 0, y: 0 });

    for (const year of estimateOutput.years) {
      if (year.year === 0) {
        coords.push({ x: year.year, y: 0 });
      } else {
        coords.push({
          x: year.year,
          y: (year.yearSummary.lossesTotal / divisor) * -1,
        });
      }
    }

    return {
      label: 'Total Expenses',
      key: 'totalExpenses',
      color: ChartOrange,
      coordinates: coords,
    };
  }, [estimateOutput, divisor]);

  const lines = [
    yearlyExpenseLine,
    yearlyOutOfPocketLine,
    yearlyRentalIncomeLine,
  ];

  /* rows */
  const tableRows: RowProps[] = useMemo(() => {
    const rows: RowProps[] = [];

    for (let i = 0; i < yearlyExpenseLine.coordinates.length; i++) {
      if (yearlyExpenseLine.coordinates[i].x < 1) continue;

      const cashFlow = yearlyOutOfPocketLine.coordinates[i].y;
      const cashFlowCell: CellConfig = {
        value: `$${Math.round(cashFlow).toLocaleString()}`,
        style: {
          color: cashFlow >= 0 ? ChartGreen : ChartRed,
        },
      };

      rows.push({
        cells: [
          (i - 1).toString(),
          `$${Math.round(
            yearlyExpenseLine.coordinates[i].y * -1
          ).toLocaleString()}`,
          `$${Math.round(
            yearlyRentalIncomeLine.coordinates[i].y
          ).toLocaleString()}`,
          cashFlowCell,
        ],
      });
    }

    return rows;
  }, [yearlyExpenseLine, yearlyRentalIncomeLine, yearlyOutOfPocketLine]);

  return (
    <Box
      style={{
        height: `calc(${height}px - 1rem)`,
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
      }}
    >
      <ChartHeader
        title="Cash Flow"
        subtitle="Cash flow is the difference between your rental income and your expenses."
        className="text-input-primary"
      />

      <Grid container style={{ flexGrow: 1, overflow: 'auto' }}>
        {/* table */}
        <Grid
          item
          xs={4}
          style={{
            paddingBottom: '1rem',
            height: 'calc(100% - 1rem)', // TODO: fix this
            overflow: 'auto',
          }}
        >
          <Switch
            checked={isMonthly}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setIsMonthly(e.target.checked)
            }
            style={{ margin: '0 0.5rem', justifyContent: 'center' }}
            leftLabel="Yearly"
            rightLabel="Monthly"
            className="switch-dark-theme"
          />
          <Table
            headers={['Year', 'Expenses', 'Rental Income', 'Cash Flow']}
            rows={tableRows}
            className="table-dark-theme"
          />
        </Grid>

        {/* chart */}
        <Grid item xs={8} style={{ height: '100%', padding: '3rem' }}>
          <LineChart
            lines={lines}
            xLabel="Year"
            yLabel="Dollars ($)"
            className="chart-dark-theme"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default CashFlowChart;
