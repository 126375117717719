import React, { useRef, useState, useEffect, Suspense } from 'react';
import TopNavBar from '../../features/top-nav-bar/top-nav-bar';
import Grid from '../../components/grid/grid';
import Typography, {
  TypographyVariant,
} from '../../components/typography/typography';
import Button from '../../components/button/button';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/routes';
import Footer from '../../features/footer/footer';

// Lazy load TypewriterSearch component
const LazyTypewriterSearch = React.lazy(
  () => import('../../components/typewriter-search/typewriter-search')
);

function Home() {
  const navigate = useNavigate();
  const topNavBarRef = useRef(null);
  const [topNavBarHeight, setTopNavBarHeight] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    if (topNavBarRef.current) {
      setTopNavBarHeight((topNavBarRef.current as HTMLElement).offsetHeight);
    }
  }, []);

  const handleBuyersClick = () => {
    navigate(routes.buyerDashboard);
  };

  const handleInvestorsClick = () => {
    navigate(routes.investorDashboard);
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        overflowX: 'hidden',
        background: '#0a0c1b',
      }}
    >
      <TopNavBar ref={topNavBarRef} setMaxWidth={true} />

      {/* Hero Section */}
      <Grid container className="bg-gradient-hero section-padding">
        {/* Animated background elements */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            opacity: 0.1,
          }}
          className="animated-glow"
        />

        {/* Add max-width wrapper */}
        <Grid container style={{ maxWidth: '1400px', margin: '0 auto' }}>
          {/* Hero Content */}
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '0 32px',
              position: 'relative',
              zIndex: 1,
            }}
          >
            <div className="accent-badge" style={{ marginBottom: '24px' }}>
              <Typography
                className="text-primary"
                style={{
                  fontSize: '0.875rem',
                  fontWeight: 500,
                }}
              >
                Property Analysis Reimagined
              </Typography>
            </div>

            <Typography
              variant={TypographyVariant.H1}
              style={{
                marginBottom: '24px',
                fontSize: '3.5rem',
                fontWeight: 700,
                lineHeight: 1.2,
                textAlign: 'left',
                color: 'white',
                textShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              Make Smarter Real Estate Decisions
            </Typography>

            <Typography
              variant={TypographyVariant.H4}
              style={{
                marginBottom: '48px',
                color: '#94a3b8',
                fontSize: '1.5rem',
                fontWeight: 400,
                textAlign: 'left',
              }}
            >
              Prop Cost is your comprehensive analysis tool for understanding
              the true cost of home ownership and real estate investment. Stop
              guessing, start knowing.
            </Typography>

            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={handleBuyersClick}
                  className="hero-button primary"
                >
                  Home Buyers
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={handleInvestorsClick}
                  className="hero-button secondary"
                >
                  Investors
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Lazy load TypewriterSearch */}
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '48px',
            }}
          >
            <Suspense fallback={<div>Loading...</div>}>
              <LazyTypewriterSearch />
            </Suspense>
          </Grid>
        </Grid>
      </Grid>

      {/* Features Section */}
      <Grid
        container
        className="bg-dark-secondary section-padding"
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Animated background glow */}
        <div
          className="animated-glow"
          style={{ position: 'absolute', inset: 0 }}
        />

        {/* Update content wrapper max-width */}
        <Grid container style={{ maxWidth: '1400px', margin: '0 auto' }}>
          {/* Section Header */}
          <Grid
            item
            xs={12}
            style={{
              position: 'relative',
              zIndex: 1,
              textAlign: 'center',
              marginBottom: '64px',
            }}
          >
            <div className="accent-badge" style={{ margin: '0 auto 24px' }}>
              <Typography
                className="text-primary"
                style={{ fontSize: '0.875rem' }}
              >
                Comprehensive Analysis
              </Typography>
            </div>
            <Typography
              variant={TypographyVariant.H2}
              className="hero-title"
              style={{ marginBottom: '24px' }}
            >
              Complete Financial Picture
            </Typography>
            <Typography
              className="hero-subtitle"
              style={{ maxWidth: '600px', margin: '0 auto' }}
            >
              Get a complete financial picture of your property investment
              before making a decision
            </Typography>
          </Grid>

          {/* Feature Cards */}
          <Grid container spacing={4}>
            <Grid item xs={12} md={3}>
              <div
                className="glass-panel"
                style={{ padding: '32px', height: '100%' }}
              >
                <Typography
                  variant={TypographyVariant.H5}
                  className="text-primary"
                  style={{
                    marginBottom: '16px',
                    fontSize: '1.5rem',
                    fontWeight: 600,
                  }}
                >
                  Home Buyer Analysis
                </Typography>
                <Typography
                  className="text-secondary"
                  style={{ lineHeight: 1.6 }}
                >
                  Compare buying vs renting with our detailed 50-year projection
                  that includes all costs, from mortgage payments to yearly
                  maintenance.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div
                className="glass-panel"
                style={{ padding: '32px', height: '100%' }}
              >
                <Typography
                  variant={TypographyVariant.H5}
                  className="text-primary"
                  style={{
                    marginBottom: '16px',
                    fontSize: '1.5rem',
                    fontWeight: 600,
                  }}
                >
                  Investment Analysis
                </Typography>
                <Typography
                  className="text-secondary"
                  style={{ lineHeight: 1.6 }}
                >
                  Evaluate rental property investments with comprehensive cash
                  flow analysis, cost breakdowns, and appreciation forecasts.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div
                className="glass-panel"
                style={{ padding: '32px', height: '100%' }}
              >
                <Typography
                  variant={TypographyVariant.H5}
                  className="text-primary"
                  style={{
                    marginBottom: '16px',
                    fontSize: '1.5rem',
                    fontWeight: 600,
                  }}
                >
                  30+ Parameters
                </Typography>
                <Typography
                  className="text-secondary"
                  style={{ lineHeight: 1.6 }}
                >
                  Factor in everything: property taxes, insurance, utilities,
                  maintenance, inflation, property appreciation, and more.
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={3}>
              <div
                className="glass-panel"
                style={{ padding: '32px', height: '100%' }}
              >
                <Typography
                  variant={TypographyVariant.H5}
                  className="text-primary"
                  style={{
                    marginBottom: '16px',
                    fontSize: '1.5rem',
                    fontWeight: 600,
                  }}
                >
                  Long-term Projections
                </Typography>
                <Typography
                  className="text-secondary"
                  style={{ lineHeight: 1.6 }}
                >
                  See your property's financial impact over 50 years, including
                  equity buildup and potential profit at sale.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* CTA Section */}
      <Grid
        container
        style={{
          background: '#23252f',
          padding: '120px 32px',
          position: 'relative',
          zIndex: 1,
          overflow: 'hidden',
        }}
      >
        <div
          className="animated-glow"
          style={{
            position: 'absolute',
            inset: 0,
            opacity: 0.05,
          }}
        />
        <Grid
          container
          style={{
            maxWidth: '1400px',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography
            variant={TypographyVariant.H2}
            className="hero-title"
            style={{
              fontSize: '2.5rem',
              marginBottom: '24px',
              maxWidth: '800px',
            }}
          >
            Ready to Make an Informed Decision?
          </Typography>
          <Typography
            className="text-primary"
            style={{
              fontSize: '1.25rem',
              marginBottom: '32px',
              opacity: 0.9,
              maxWidth: '800px',
            }}
          >
            No signup required. Start your analysis now and get the complete
            financial picture of your real estate decision.
          </Typography>
          <Button
            variant="contained"
            onClick={handleBuyersClick}
            style={{
              background: 'linear-gradient(90deg, #5da8ee 0%, #3a7ced 100%)',
              color: '#ffffff',
              padding: '16px 32px',
              fontSize: '1.125rem',
              fontWeight: 600,
              borderRadius: '8px',
              textTransform: 'none',
            }}
            className="hero-button primary"
          >
            Try Free
          </Button>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}

// Add this to your global CSS or styled-components
const styles = `
  @keyframes pulse {
    0% { transform: scale(1); opacity: 0.1; }
    50% { transform: scale(1.1); opacity: 0.2; }
    100% { transform: scale(1); opacity: 0.1; }
  }
`;

export default Home;
