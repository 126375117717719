import React, { useMemo, useState } from 'react';
import Select, { SelectOptionProps } from '../../../components/select/select';
import Box from '../../../components/box/box';
import { PieChartSliceProps } from '../../../components/pie-chart/pie-chart';
import { EstimateOutput, Year } from '../../../api/models/EstimateOutput';
import { addSpacesToCamelCaseString } from '../../../utils/string-formatters';
import { getColors } from '../../../utils/constants/color';
import Grid from '../../../components/grid/grid';
import { IconType } from '../../../components/icons/icon';
import DataCard from '../../../components/data-card/data-card';
import YearlyExpenseLegend from '../../../components/legend/legend';
import PieChart from '../../../components/pie-chart/pie-chart';
import ChartHeader from './chart-header';

interface YearlyExpensePieChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
}

function YearlyExpensePieChart({
  estimateOutput,
  height,
}: YearlyExpensePieChartProps) {
  const [selectedYearNumber, setSelectedYearNumber] = useState<number>(0);

  // dropdown options
  const yearSelectOptions: SelectOptionProps[] = useMemo(() => {
    const years: SelectOptionProps[] = [];

    for (const year of estimateOutput.years) {
      years.push({ label: year.year.toString(), value: year.year });
    }

    return years;
  }, [estimateOutput]);

  // handle year change
  const handleYearChange = (yearString: string) => {
    const yearNum = parseInt(yearString);
    setSelectedYearNumber(yearNum);
  };

  const selectedYear: Year | undefined = useMemo(() => {
    return estimateOutput.years.find((y) => y.year === selectedYearNumber);
  }, [estimateOutput, selectedYearNumber]);

  // set pie chart slices
  const pieChartSlices: PieChartSliceProps[] = useMemo(() => {
    const slices: PieChartSliceProps[] = [];

    if (selectedYear === undefined) {
      return slices;
    }

    const colors: string[] = getColors(selectedYear?.losses?.length ?? 0);

    for (let i = 0; i < (selectedYear?.losses?.length ?? 0); i++) {
      const expense = selectedYear.losses[i];
      if (expense.amount === 0) {
        continue;
      }

      slices.push({
        label: addSpacesToCamelCaseString(expense.subject),
        value: expense.amount,
        id: i,
      });
    }

    return slices
      .sort((a, b) => b.value - a.value)
      .map((slice, index) => {
        return { ...slice, color: colors[index % colors.length] };
      });
  }, [selectedYear]);

  // render
  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: `calc(${height}px - 1rem)`,
          width: '100%',
        }}
      >
        <ChartHeader
          title="Expense Breakdown"
          subtitle="The expense breakdown of home ownership for each year."
          className="text-input-primary"
        />

        {selectedYear !== undefined && (
          <Grid
            container
            style={{ flexGrow: 1, overflow: 'hidden', paddingTop: '1rem' }}
          >
            {/* legend */}
            <Grid
              item
              xs={4}
              style={{
                overflow: 'hidden',
                height: '100%',
                paddingBottom: '1rem',
                paddingRight: '1rem',
                paddingLeft: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Select
                value={selectedYear.year.toString()}
                onChange={handleYearChange}
                options={yearSelectOptions}
                label="Year"
                style={{ width: '100%', marginTop: '0.5rem' }}
                className="select-dark-theme "
              />

              <YearlyExpenseLegend
                rows={pieChartSlices.map((slice) => {
                  return {
                    color: slice.color || '',
                    label: slice.label,
                    value: `$${Math.round(slice.value).toLocaleString()}`,
                    additionalValue: `$${Math.round(
                      slice.value / 12
                    ).toLocaleString()}`,
                  };
                })}
                className="gray-rounded-border table-dark-theme text-input-primary"
              />
            </Grid>

            {/* pie chart */}
            <Grid item xs={6} style={{ padding: '1rem' }}>
              <PieChart data={pieChartSlices} />
            </Grid>

            {/* summary */}
            <Grid
              item
              xs={2}
              style={{
                overflow: 'auto',
                height: 'calc(100% - 1rem)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'start',
              }}
            >
              {selectedYearNumber !== 0 && (
                <DataCard
                  title={`Per Month`}
                  body={`$${Math.round(
                    selectedYear.yearSummary.lossesTotal / 12
                  ).toLocaleString()}`}
                  iconVariant={IconType.Calendar}
                  style={{ marginTop: '8px' }}
                  infoTitle="Per Month"
                  infoMessage="The average monthly expenses for the year."
                />
              )}

              <DataCard
                title={`Year ${selectedYear.year} total`}
                body={`$${Math.round(
                  selectedYear.yearSummary.lossesTotal
                ).toLocaleString()}`}
                iconVariant={IconType.Schedule}
                style={{ marginTop: '8px' }}
                infoTitle="Year Total"
                infoMessage="The total expenses for the year."
              />

              {/* <DataCard
                title="YTD Loss"
                body={`$${Math.round(
                  selectedYear.totalSummary.lossesTotal / -1
                ).toLocaleString()}`}
                iconVariant={IconType.TrendingDown}
                style={{ marginTop: '8px' }}
                infoTitle="YTD Loss"
                infoMessage="The total expenses for the year-to-date."
              />

              <DataCard
                title="YTD Net"
                body={`$${Math.round(
                  selectedYear.totalSummary.net
                ).toLocaleString()}`}
                iconVariant={IconType.Balance}
                style={{ marginTop: '8px' }}
                infoTitle="YTD Net"
                infoMessage="The total gained through vested equity and appreciation minus the total costs year-to-date."
              /> */}
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
}

export default YearlyExpensePieChart;
