import React, { useRef, useState, useEffect } from 'react';
import TopNavBar from '../../features/top-nav-bar/top-nav-bar';
import Grid from '../../components/grid/grid';
import Typography, {
  TypographyVariant,
} from '../../components/typography/typography';
import Footer from '../../features/footer/footer';
import { useNavigate } from 'react-router-dom';

function About() {
  const topNavBarRef = useRef(null);
  const [topNavBarHeight, setTopNavBarHeight] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    if (topNavBarRef.current) {
      setTopNavBarHeight((topNavBarRef.current as HTMLElement).offsetHeight);
    }
  }, []);

  const handleFeedbackClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate('/contact');
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        overflowX: 'hidden',
        background: '#0a0c1b',
      }}
    >
      <TopNavBar ref={topNavBarRef} setMaxWidth={true} />

      {/* Hero Section */}
      <Grid
        container
        className="bg-gradient-hero section-padding-small"
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Animated background glow */}
        <div
          className="animated-glow"
          style={{ position: 'absolute', inset: 0 }}
        />

        {/* Content wrapper */}
        <Grid container className="content-max-width">
          <Grid
            item
            xs={12}
            md={10}
            style={{
              position: 'relative',
              zIndex: 1,
              margin: '0 auto',
            }}
          >
            {/* Section Badge */}
            <div className="accent-badge" style={{ marginBottom: '24px' }}>
              <Typography
                className="text-primary"
                style={{ fontSize: '0.875rem' }}
              >
                Our Approach
              </Typography>
            </div>

            <Typography
              variant={TypographyVariant.H2}
              className="hero-title"
              style={{ marginBottom: '24px' }}
            >
              About Prop Cost
            </Typography>

            {/* Mission Statement Section */}
            <div
              className="glass-panel"
              style={{ padding: '32px', marginBottom: '32px' }}
            >
              <Typography
                className="text-secondary"
                style={{ lineHeight: 1.8 }}
              >
                At Prop Cost, our mission is to provide the most comprehensive
                property cost analysis available, helping homebuyers and
                investors make fully informed decisions. Our platform goes
                beyond basic mortgage calculators by incorporating over 30
                financial factors to give a detailed, long-term view of
                ownership costs.
              </Typography>
            </div>

            {/* Free Usage Section */}
            <div
              className="glass-panel"
              style={{ padding: '32px', marginBottom: '48px' }}
            >
              <Typography
                variant={TypographyVariant.H5}
                className="text-primary"
                style={{ marginBottom: '16px' }}
              >
                Free to Use – We Want Your Feedback!
              </Typography>
              <Typography
                className="text-secondary"
                style={{ lineHeight: 1.8, marginBottom: '16px' }}
              >
                Prop Cost is completely free to use. We're in the process of
                validating and improving our tool, and your feedback is
                invaluable in shaping its future. Whether you're a homebuyer,
                investor, or just exploring real estate, we'd love to hear your
                thoughts on how the platform can be more useful.
              </Typography>
              <a
                href="/contact"
                className="text-primary"
                onClick={handleFeedbackClick}
                style={{
                  textDecoration: 'none',
                  display: 'inline-block',
                  fontWeight: 500,
                  fontSize: '1rem',
                }}
              >
                Share your feedback →
              </a>
            </div>

            {/* What We Consider Section */}
            <Typography
              variant={TypographyVariant.H4}
              className="text-primary"
              style={{
                marginBottom: '32px',
                fontSize: '1.75rem',
                fontWeight: 600,
              }}
            >
              What We Consider
            </Typography>

            <Grid container spacing={4}>
              {/* Purchase & Financing */}
              <Grid item xs={12} md={6}>
                <div
                  className="glass-panel"
                  style={{ padding: '24px', height: 'auto' }}
                >
                  <Typography
                    variant={TypographyVariant.H5}
                    className="text-primary"
                    style={{ marginBottom: '16px' }}
                  >
                    Purchase & Financing
                  </Typography>
                  <Typography
                    className="text-secondary"
                    style={{ lineHeight: 1.6 }}
                  >
                    • Complete purchase price analysis
                    <br />
                    • Detailed loan structuring
                    <br />
                    • All closing costs
                    <br />• Loan origination fees and settlement costs
                  </Typography>
                </div>
              </Grid>

              {/* Annual Expenses */}
              <Grid item xs={12} md={6}>
                <div
                  className="glass-panel"
                  style={{ padding: '24px', height: 'auto' }}
                >
                  <Typography
                    variant={TypographyVariant.H5}
                    className="text-primary"
                    style={{ marginBottom: '16px' }}
                  >
                    Annual Expenses
                  </Typography>
                  <Typography
                    className="text-secondary"
                    style={{ lineHeight: 1.6 }}
                  >
                    • Property taxes based on market value
                    <br />
                    • Comprehensive insurance coverage
                    <br />
                    • Regular maintenance and repairs
                    <br />
                    • Complete utility costs breakdown
                    <br />• HOA fees where applicable
                  </Typography>
                </div>
              </Grid>

              {/* For Investors */}
              <Grid item xs={12} md={6}>
                <div
                  className="glass-panel"
                  style={{ padding: '24px', height: 'auto' }}
                >
                  <Typography
                    variant={TypographyVariant.H5}
                    className="text-primary"
                    style={{ marginBottom: '16px' }}
                  >
                    For Investors
                  </Typography>
                  <Typography
                    className="text-secondary"
                    style={{ lineHeight: 1.6 }}
                  >
                    • Rental income projections
                    <br />
                    • Property management costs
                    <br />
                    • Vacancy rate impacts
                    <br />
                    • Specialized landlord insurance
                    <br />• Rent appreciation forecasting
                  </Typography>
                </div>
              </Grid>

              {/* Exit Strategy */}
              <Grid item xs={12} md={6}>
                <div
                  className="glass-panel"
                  style={{ padding: '24px', height: 'auto' }}
                >
                  <Typography
                    variant={TypographyVariant.H5}
                    className="text-primary"
                    style={{ marginBottom: '16px' }}
                  >
                    Exit Strategy Analysis
                  </Typography>
                  <Typography
                    className="text-secondary"
                    style={{ lineHeight: 1.6 }}
                  >
                    • Real estate agent commissions
                    <br />
                    • Pre-sale inspection costs
                    <br />
                    • Staging expenses
                    <br />
                    • Settlement fees
                    <br />• Net profit calculations
                  </Typography>
                </div>
              </Grid>
            </Grid>

            {/* How We Calculate Section */}
            <Typography
              variant={TypographyVariant.H4}
              className="text-primary"
              style={{
                marginTop: '48px',
                marginBottom: '24px',
                fontSize: '1.75rem',
                fontWeight: 600,
              }}
            >
              How We Calculate
            </Typography>

            <div
              className="glass-panel"
              style={{ padding: '32px', marginBottom: '48px' }}
            >
              <Typography
                className="text-secondary"
                style={{ lineHeight: 1.8, marginBottom: '24px' }}
              >
                Our analysis maps out the next 50 years of ownership, factoring
                in compound effects of inflation, property value appreciation,
                changes in expenses over time, building of equity, total cost of
                ownership, and return on investment.
              </Typography>
              <Typography
                className="text-secondary"
                style={{ lineHeight: 1.8 }}
              >
                Each calculation is transparent and adjustable, allowing you to
                test different scenarios, adjust assumptions, see how changes in
                one factor affect the overall financial picture, and make
                comparisons between different properties or investment
                strategies.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}

export default About;
