import React, { Fragment } from 'react';
import Typography, { TypographyVariant } from '../typography/typography';
import IconButton from '../icons/icon-button';
import Tooltip from '../tooltip/tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface InfoIconProps {
  title?: string;
  body?: string;
  disabled?: boolean;
  className?: string;
}

function InfoIcon({ title, body, disabled, className }: InfoIconProps) {
  return (
    <Tooltip
      title={
        <Fragment>
          <Typography>{title}</Typography>
          <Typography variant={TypographyVariant.Subtitle2}>{body}</Typography>
        </Fragment>
      }
      disableHoverListener={disabled}
      className={className}
    >
      <div>
        <IconButton
          className={disabled ? 'info-icon-disabled' : 'info-icon-enabled'}
        >
          <InfoOutlinedIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
}

export default InfoIcon;
