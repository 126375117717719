import React, { useEffect } from 'react';
import { EstimateOutput } from '../../../api/models/EstimateOutput';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import Grid from '../../../components/grid/grid';
import { useInvestmentHook } from '../../../utils/investment-line-hook';
import {
  InvestmentOutput,
  InvestmentOutputYear,
} from '../../../api/models/InvestmentOutput';

// TODO: add table components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { TableHead } from '@mui/material';

interface SaleChartDetailedProps {
  estimateOutput: EstimateOutput;
  height?: number;
  annualRoi: number;
}

export function SaleChartDetailed({
  estimateOutput,
  height,
  annualRoi,
}: SaleChartDetailedProps) {
  return (
    <Box
      style={{
        padding: '16px',
        height: `${height}px`,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ChartHeader
        title="Investment Comparison"
        subtitle="5% annual roi. Yearly contribution equals total expense minus rental income."
      />

      <SaleChartTable estimateOutput={estimateOutput} annualRoi={annualRoi} />
    </Box>
  );
}

export function SaleChartTable({
  estimateOutput,
  annualRoi,
}: SaleChartDetailedProps) {
  // investment line
  const investmentOutput: InvestmentOutput = useInvestmentHook(
    estimateOutput,
    annualRoi
  );

  const getRow = (year: InvestmentOutputYear) => {
    const outputYear = estimateOutput.years.find((y) => y.year === year.year);

    const netProfit = outputYear?.sale.netProfit ?? 0;

    return (
      <TableRow
        key={year.year}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align="right">{year.year}</TableCell>
        <TableCell align="right">
          ${year.yearContribution.toLocaleString()}
        </TableCell>
        <TableCell align="right">
          ${year.cumulativeContribution.toLocaleString()}
        </TableCell>
        <TableCell align="right">${netProfit.toLocaleString()}</TableCell>
        <TableCell align="right">${year.value.toLocaleString()}</TableCell>
      </TableRow>
    );
  };

  return (
    <Box
      style={{
        height: '100%',
        overflow: 'auto',
        paddingLeft: '16px',
      }}
      className="table-dark-theme"
    >
      <Grid container style={{ flexGrow: 1, paddingBottom: '24px' }}>
        <Grid item xs={12}>
          <TableContainer
            sx={{
              maxHeight: 'calc(100vh - 300px)', // Adjust based on your layout
              position: 'relative',
              overflow: 'auto',
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{
                '& .MuiTableCell-stickyHeader': {
                  position: 'sticky',
                  top: 0,
                  zIndex: 1,
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell align="right">Year Contribution</TableCell>
                  <TableCell align="right">Cumulative Contribution</TableCell>
                  <TableCell align="right">Net Profit at Sale</TableCell>
                  <TableCell align="right">
                    Alternative Investment Value
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {investmentOutput.years.map((year) => getRow(year))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}
