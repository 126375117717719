import React, { CSSProperties, ReactNode } from 'react';
import { Button as MuiButton } from '@mui/material';

interface ButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  children: ReactNode;
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error';
  className?: string;
  size?: 'small' | 'medium' | 'large';
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  variant?: 'text' | 'outlined' | 'contained';
  style?: CSSProperties;
  // add any additional props you need
}

function Button(props: ButtonProps) {
  const { className, color, children, ...otherProps } = props;

  // If children is a string, use it as the aria-label
  const buttonAriaLabel = typeof children === 'string' ? children : 'button';

  return (
    <MuiButton
      {...otherProps}
      className={className}
      aria-label={buttonAriaLabel}
    >
      {children}
    </MuiButton>
  );
}

export default Button;
