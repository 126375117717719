import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { EstimateInput } from '../../../api/models/EstimateInput';
import {
  EstimateInputForm,
  estimateInputValidationSchema,
} from '../../../models/estimate-input-form';
import TextInput from '../../../components/text-input/text-input';
import Card from '../../../components/card/card';
import CardContent from '../../../components/card/card-content';
import CardHeader from '../../../components/card/card-header';
import Paper from '../../../components/paper/paper';
import Icon, { IconType } from '../../../components/icons/icon';
import InputSection from './input-section';
import './input-form.scss';
import { DashboardViewType } from '../dashboard';
import IconButton from '../../../components/icons/icon-button';
import { debounce } from '@mui/material';
import useDeepCompareEffect from 'use-deep-compare-effect';
import InfoIcon from '../../../components/info-icon/info-icon';
import Tooltip from '../../../components/tooltip/tooltip';

interface InputFormProps {
  defaultInputs: EstimateInputForm;
  onSubmit: (data: EstimateInput) => void;
  viewType?: DashboardViewType;
}

function InputForm({ defaultInputs, onSubmit, viewType }: InputFormProps) {
  const [expandAll, setExpandAll] = useState<boolean | undefined>(undefined);
  const [lastChange, setLastChange] = useState<string>('');

  const handleExpandChange = (expanded: boolean) => {
    setExpandAll(expanded);
    setLastChange(new Date().toISOString());
  };

  const {
    register,
    handleSubmit,
    reset,
    trigger,
    watch,
    setValue,
    control,
    formState: { isValid, isDirty, errors, isValidating },
  } = useForm<EstimateInputForm>({
    defaultValues: defaultInputs,
    resolver: yupResolver(estimateInputValidationSchema),
    mode: 'onChange',
  });

  const loanAmount = watch('loanDetails.loanAmount');
  const downPayment = watch('buyingCosts.downPayment');
  const salePrice = watch('salePrice');

  // update loan amount when down payment changes
  useEffect(() => {
    setValue('loanDetails.loanAmount', salePrice - downPayment);
  }, [downPayment, setValue]);

  // update down payment when loan amount changes
  useEffect(() => {
    setValue('buyingCosts.downPayment', salePrice - loanAmount);
  }, [loanAmount, setValue]);

  // update loanAmount when sale price changes
  useEffect(() => {
    setValue('loanDetails.loanAmount', salePrice - downPayment);
  }, [salePrice, setValue]);

  const onResetHandler = () => {
    reset(defaultInputs);
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    trigger('salePrice');
  }, [loanAmount, downPayment, salePrice, trigger]);

  // reference: https://github.com/orgs/react-hook-form/discussions/7063#discussioncomment-1657441
  const debouncedSave = useCallback(
    debounce(() => {
      handleSubmit(onSubmit)();
    }, 500),
    []
  );

  const watchedData = useWatch({
    control: control,
    defaultValue: defaultInputs,
  });

  useDeepCompareEffect(() => {
    if (isDirty && isValid && !isValidating) {
      debouncedSave();
    }
  }, [watchedData, isValid, isValidating]);

  return (
    <Paper
      elevation={3}
      style={{
        overflowY: 'auto',
        minHeight: '100%',
        maxHeight: '100%',
        border: '1px solid rgba(255, 255, 255, 0.2)', // TODO: make class
      }}
      className="bg-dark-secondary"
    >
      <Card
        style={{
          padding: '8px',
          boxShadow: 'none',
          borderRadius: '0px',
          border: 'none',
        }}
        className="bg-dark-secondary text-primary"
      >
        <form className="bg-dark-secondary">
          <CardHeader
            title="Inputs"
            className="text-primary"
            style={{ padding: '8px' }}
            action={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <Tooltip title="Expand All">
                  <div>
                    <IconButton onClick={() => handleExpandChange(true)}>
                      <Icon
                        iconType={IconType.UnfoldMore}
                        className="text-primary"
                      />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title="Collapse All">
                  <div>
                    <IconButton onClick={() => handleExpandChange(false)}>
                      <Icon
                        iconType={IconType.UnfoldLess}
                        className="text-primary"
                      />
                    </IconButton>
                  </div>
                </Tooltip>
                <Tooltip title="Reset">
                  <div>
                    <IconButton onClick={onResetHandler}>
                      <Icon
                        iconType={IconType.Refresh}
                        className="text-primary"
                      />
                    </IconButton>
                  </div>
                </Tooltip>
                <InfoIcon
                  title="Inputs"
                  body="Tailoring these inputs ensures a personalized assessment of home ownership costs. Consult the About page for more information."
                />
              </div>
            }
          />

          <CardContent
            style={{ padding: '8px' }}
            className="white-inputs mui-dark-input"
          >
            <InputSection
              sectionTitle="BASICS"
              parentExpanded={expandAll}
              lastChange={lastChange}
            >
              <TextInput
                id="salePrice"
                label="Sale Price"
                type="number"
                prefix="$"
                {...register('salePrice')}
                defaultValue={defaultInputs.salePrice}
                hasError={!!errors.salePrice}
                errorMessage={errors.salePrice?.message}
              />

              <TextInput
                id="inflationRate"
                label="Inflation Rate"
                type="number"
                prefix="%"
                {...register('inflationRate')}
                defaultValue={defaultInputs.inflationRate}
                hasError={!!errors.inflationRate}
                errorMessage={errors.inflationRate?.message}
              />
            </InputSection>

            <InputSection
              sectionTitle="LOAN"
              parentExpanded={expandAll}
              lastChange={lastChange}
            >
              <TextInput
                id="buyingCosts.downPayment"
                label="Down Payment"
                type="number"
                prefix="$"
                {...register('buyingCosts.downPayment')}
                defaultValue={defaultInputs.buyingCosts.downPayment}
                hasError={!!errors.buyingCosts?.downPayment}
                errorMessage={errors.buyingCosts?.downPayment?.message}
              />

              <TextInput
                id="loanDetails.loanAmount"
                label="Loan Amount"
                type="number"
                prefix="$"
                {...register('loanDetails.loanAmount')}
                defaultValue={defaultInputs.loanDetails.loanAmount}
                hasError={!!errors.loanDetails?.loanAmount}
                errorMessage={errors.loanDetails?.loanAmount?.message}
              />

              <TextInput
                id="loanDetails.loanInterestRate"
                label="Interest Rate"
                type="number"
                prefix="%"
                {...register('loanDetails.loanInterestRate')}
                defaultValue={defaultInputs.loanDetails.loanInterestRate}
                hasError={!!errors.loanDetails?.loanInterestRate}
                errorMessage={errors.loanDetails?.loanInterestRate?.message}
              />

              <TextInput
                id="loanDetails.loanDurationYears"
                label="Loan Duration (Years)"
                type="number"
                {...register('loanDetails.loanDurationYears')}
                defaultValue={defaultInputs.loanDetails.loanDurationYears}
                hasError={!!errors.loanDetails?.loanDurationYears}
                errorMessage={errors.loanDetails?.loanDurationYears?.message}
              />
            </InputSection>

            <InputSection
              sectionTitle="BUYING COSTS"
              parentExpanded={expandAll}
              lastChange={lastChange}
            >
              <TextInput
                id="buyingCosts.titleSearch"
                label="Title Search"
                type="number"
                prefix="$"
                {...register('buyingCosts.titleSearch')}
                defaultValue={defaultInputs.buyingCosts.titleSearch}
                hasError={!!errors.buyingCosts?.titleSearch}
                errorMessage={errors.buyingCosts?.titleSearch?.message}
              />

              <TextInput
                id="buyingCosts.appraisalFee"
                label="Appraisal Fee"
                type="number"
                prefix="$"
                {...register('buyingCosts.appraisalFee')}
                defaultValue={defaultInputs.buyingCosts.appraisalFee}
                hasError={!!errors.buyingCosts?.appraisalFee}
                errorMessage={errors.buyingCosts?.appraisalFee?.message}
              />

              <TextInput
                id="buyingCosts.inspectionFee"
                label="Inspection Fee"
                type="number"
                prefix="$"
                {...register('buyingCosts.inspectionFee')}
                defaultValue={defaultInputs.buyingCosts.inspectionFee}
                hasError={!!errors.buyingCosts?.inspectionFee}
                errorMessage={errors.buyingCosts?.inspectionFee?.message}
              />

              <TextInput
                id="buyingCosts.recordingFee"
                label="Recording Fee"
                type="number"
                prefix="$"
                {...register('buyingCosts.recordingFee')}
                defaultValue={defaultInputs.buyingCosts.recordingFee}
                hasError={!!errors.buyingCosts?.recordingFee}
                errorMessage={errors.buyingCosts?.recordingFee?.message}
              />

              <TextInput
                id="buyingCosts.loanOriginationFeePercentOfLoanAmount"
                label="Loan Origination Fee (% of Loan Amount)"
                type="number"
                prefix="%"
                {...register(
                  'buyingCosts.loanOriginationFeePercentOfLoanAmount'
                )}
                defaultValue={
                  defaultInputs.buyingCosts
                    .loanOriginationFeePercentOfLoanAmount
                }
                hasError={
                  !!errors.buyingCosts?.loanOriginationFeePercentOfLoanAmount
                }
                errorMessage={
                  errors.buyingCosts?.loanOriginationFeePercentOfLoanAmount
                    ?.message
                }
              />

              <TextInput
                id="buyingCosts.surveyFee"
                label="Survey Fee"
                type="number"
                prefix="$"
                {...register('buyingCosts.surveyFee')}
                defaultValue={defaultInputs.buyingCosts.surveyFee}
                hasError={!!errors.buyingCosts?.surveyFee}
                errorMessage={errors.buyingCosts?.surveyFee?.message}
              />

              <TextInput
                id="buyingCosts.settlementFee"
                label="Settlement Fee"
                type="number"
                prefix="$"
                {...register('buyingCosts.settlementFee')}
                defaultValue={defaultInputs.buyingCosts.settlementFee}
                hasError={!!errors.buyingCosts?.settlementFee}
                errorMessage={errors.buyingCosts?.settlementFee?.message}
              />

              <TextInput
                id="buyingCosts.titleInsurancePercentOfSalePrice"
                label="Title Insurance (% of Sale Price)"
                type="number"
                prefix="%"
                {...register('buyingCosts.titleInsurancePercentOfSalePrice')}
                defaultValue={
                  defaultInputs.buyingCosts.titleInsurancePercentOfSalePrice
                }
                hasError={
                  !!errors.buyingCosts?.titleInsurancePercentOfSalePrice
                }
                errorMessage={
                  errors.buyingCosts?.titleInsurancePercentOfSalePrice?.message
                }
              />
            </InputSection>

            <InputSection
              sectionTitle="EXPENSES"
              parentExpanded={expandAll}
              lastChange={lastChange}
            >
              <TextInput
                id="expenses.propertyTaxRatePercentOfMarketValue"
                label="Property Tax Rate (% of Market Value)"
                type="number"
                prefix="%"
                {...register('expenses.propertyTaxRatePercentOfMarketValue')}
                defaultValue={
                  defaultInputs.expenses.propertyTaxRatePercentOfMarketValue
                }
                hasError={
                  !!errors.expenses?.propertyTaxRatePercentOfMarketValue
                }
                errorMessage={
                  errors.expenses?.propertyTaxRatePercentOfMarketValue?.message
                }
              />

              <TextInput
                id="expenses.homeInsurancePerYear"
                label="Home Insurance (per year)"
                type="number"
                prefix="$"
                {...register('expenses.homeInsurancePerYear')}
                defaultValue={defaultInputs.expenses.homeInsurancePerYear}
                hasError={!!errors.expenses?.homeInsurancePerYear}
                errorMessage={errors.expenses?.homeInsurancePerYear?.message}
              />

              <TextInput
                id="expenses.repairsPerYearPercentOfMarketValue"
                label="Repairs (per year, % of Market Value)"
                type="number"
                prefix="%"
                {...register('expenses.repairsPerYearPercentOfMarketValue')}
                defaultValue={
                  defaultInputs.expenses.repairsPerYearPercentOfMarketValue
                }
                hasError={!!errors.expenses?.repairsPerYearPercentOfMarketValue}
                errorMessage={
                  errors.expenses?.repairsPerYearPercentOfMarketValue?.message
                }
              />

              {viewType === DashboardViewType.Buyer && (
                <>
                  <TextInput
                    id="expenses.electricityPerMonth"
                    label="Electricity (per month)"
                    type="number"
                    prefix="$"
                    {...register('expenses.electricityPerMonth')}
                    defaultValue={defaultInputs.expenses.electricityPerMonth}
                    hasError={!!errors.expenses?.electricityPerMonth}
                    errorMessage={errors.expenses?.electricityPerMonth?.message}
                  />

                  <TextInput
                    id="expenses.heatingPerMonth"
                    label="Heating (per month)"
                    type="number"
                    prefix="$"
                    {...register('expenses.heatingPerMonth')}
                    defaultValue={defaultInputs.expenses.heatingPerMonth}
                    hasError={!!errors.expenses?.heatingPerMonth}
                    errorMessage={errors.expenses?.heatingPerMonth?.message}
                  />

                  <TextInput
                    id="expenses.waterPerMonth"
                    label="Water (per month)"
                    type="number"
                    prefix="$"
                    {...register('expenses.waterPerMonth')}
                    defaultValue={defaultInputs.expenses.waterPerMonth}
                    hasError={!!errors.expenses?.waterPerMonth}
                    errorMessage={errors.expenses?.waterPerMonth?.message}
                  />

                  <TextInput
                    id="expenses.internetPerMonth"
                    label="Internet (per month)"
                    type="number"
                    prefix="$"
                    {...register('expenses.internetPerMonth')}
                    defaultValue={defaultInputs.expenses.internetPerMonth}
                    hasError={!!errors.expenses?.internetPerMonth}
                    errorMessage={errors.expenses?.internetPerMonth?.message}
                  />

                  <TextInput
                    id="expenses.sewagePerMonth"
                    label="Sewage (per month)"
                    type="number"
                    prefix="$"
                    {...register('expenses.sewagePerMonth')}
                    defaultValue={defaultInputs.expenses.sewagePerMonth}
                    hasError={!!errors.expenses?.sewagePerMonth}
                    errorMessage={errors.expenses?.sewagePerMonth?.message}
                  />

                  <TextInput
                    id="expenses.garbagePerMonth"
                    label="Garbage (per month)"
                    type="number"
                    prefix="$"
                    {...register('expenses.garbagePerMonth')}
                    defaultValue={defaultInputs.expenses.garbagePerMonth}
                    hasError={!!errors.expenses?.garbagePerMonth}
                    errorMessage={errors.expenses?.garbagePerMonth?.message}
                  />

                  <TextInput
                    id="expenses.hoaFeesPerMonth"
                    label="HOA Fees (per month)"
                    type="number"
                    prefix="$"
                    {...register('expenses.hoaFeesPerMonth')}
                    defaultValue={defaultInputs.expenses.hoaFeesPerMonth}
                    hasError={!!errors.expenses?.hoaFeesPerMonth}
                    errorMessage={errors.expenses?.hoaFeesPerMonth?.message}
                  />

                  <TextInput
                    id="expenses.otherPerYear"
                    label="Other (per year)"
                    type="number"
                    prefix="$"
                    {...register('expenses.otherPerYear')}
                    defaultValue={defaultInputs.expenses.otherPerYear}
                    hasError={!!errors.expenses?.otherPerYear}
                    errorMessage={errors.expenses?.otherPerYear?.message}
                  />
                </>
              )}

              {viewType === DashboardViewType.Investor && (
                <>
                  <TextInput
                    id="rent.landlordInsurancePerYear"
                    label="Landlord Insurance (per year)"
                    type="number"
                    prefix="$"
                    {...register('rent.landlordInsurancePerYear')}
                    defaultValue={defaultInputs.rent.landlordInsurancePerYear}
                    hasError={!!errors.rent?.landlordInsurancePerYear}
                    errorMessage={
                      errors.rent?.landlordInsurancePerYear?.message
                    }
                  />
                </>
              )}
            </InputSection>

            <InputSection
              sectionTitle="GAINS"
              parentExpanded={expandAll}
              lastChange={lastChange}
            >
              <TextInput
                id="gains.propertyAppreciationRate"
                label="Property Appreciation (per year)"
                type="number"
                prefix="%"
                {...register('gains.propertyAppreciationRate')}
                defaultValue={defaultInputs.gains.propertyAppreciationRate}
                hasError={!!errors.gains?.propertyAppreciationRate}
                errorMessage={errors.gains?.propertyAppreciationRate?.message}
              />

              {viewType === DashboardViewType.Buyer && (
                <TextInput
                  id="income"
                  label="Household Income"
                  type="number"
                  prefix="$"
                  {...register('income')}
                  defaultValue={defaultInputs.income}
                  hasError={!!errors.income}
                  errorMessage={errors.income?.message}
                />
              )}
            </InputSection>

            {viewType === DashboardViewType.Investor && (
              <InputSection
                sectionTitle="RENT"
                parentExpanded={expandAll}
                lastChange={lastChange}
              >
                <TextInput
                  id="rent.rentPerMonth"
                  label="Rent (per month)"
                  type="number"
                  prefix="$"
                  {...register('rent.rentPerMonth')}
                  defaultValue={defaultInputs.rent.rentPerMonth}
                  hasError={!!errors.rent?.rentPerMonth}
                  errorMessage={errors.rent?.rentPerMonth?.message}
                />

                <TextInput
                  id="rent.propertyManagementFeePercentOfRent"
                  label="Property Management Fee (% of Rent)"
                  type="number"
                  prefix="%"
                  {...register('rent.propertyManagementFeePercentOfRent')}
                  defaultValue={
                    defaultInputs.rent.propertyManagementFeePercentOfRent
                  }
                  hasError={!!errors.rent?.propertyManagementFeePercentOfRent}
                  errorMessage={
                    errors.rent?.propertyManagementFeePercentOfRent?.message
                  }
                />

                <TextInput
                  id="rent.vacancyPercentPerYear"
                  label="Vacancy (% per year)"
                  type="number"
                  prefix="%"
                  {...register('rent.vacancyPercentPerYear')}
                  defaultValue={defaultInputs.rent.vacancyPercentPerYear}
                  hasError={!!errors.rent?.vacancyPercentPerYear}
                  errorMessage={errors.rent?.vacancyPercentPerYear?.message}
                />

                <TextInput
                  id="rent.rentAppreciationRate"
                  label="Rent Appreciation (per year)"
                  type="number"
                  prefix="%"
                  {...register('rent.rentAppreciationRate')}
                  defaultValue={defaultInputs.rent.rentAppreciationRate}
                  hasError={!!errors.rent?.rentAppreciationRate}
                  errorMessage={errors.rent?.rentAppreciationRate?.message}
                />
              </InputSection>
            )}

            <InputSection
              sectionTitle="SELLING COSTS"
              parentExpanded={expandAll}
              lastChange={lastChange}
            >
              <TextInput
                id="sellingCosts.realEstateAgentCommissionPercentOfMarketValue"
                label="Real Estate Agent Commission (% of Market Value)"
                type="number"
                prefix="%"
                {...register(
                  'sellingCosts.realEstateAgentCommissionPercentOfMarketValue'
                )}
                defaultValue={
                  defaultInputs.sellingCosts
                    .realEstateAgentCommissionPercentOfMarketValue
                }
                hasError={
                  !!errors.sellingCosts
                    ?.realEstateAgentCommissionPercentOfMarketValue
                }
                errorMessage={
                  errors.sellingCosts
                    ?.realEstateAgentCommissionPercentOfMarketValue?.message
                }
              />

              <TextInput
                id="sellingCosts.preSaleInspection"
                label="Pre-Sale Inspection"
                type="number"
                prefix="$"
                {...register('sellingCosts.preSaleInspection')}
                defaultValue={defaultInputs.sellingCosts.preSaleInspection}
                hasError={!!errors.sellingCosts?.preSaleInspection}
                errorMessage={errors.sellingCosts?.preSaleInspection?.message}
              />

              <TextInput
                id="sellingCosts.staging"
                label="Staging Cost"
                type="number"
                prefix="$"
                {...register('sellingCosts.staging')}
                defaultValue={defaultInputs.sellingCosts.staging}
                hasError={!!errors.sellingCosts?.staging}
                errorMessage={errors.sellingCosts?.staging?.message}
              />

              <TextInput
                id="sellingCosts.settlementFee"
                label="Settlement Fee"
                type="number"
                prefix="$"
                {...register('sellingCosts.settlementFee')}
                defaultValue={defaultInputs.sellingCosts.settlementFee}
                hasError={!!errors.sellingCosts?.settlementFee}
                errorMessage={errors.sellingCosts?.settlementFee?.message}
              />
            </InputSection>
          </CardContent>
        </form>
      </Card>
    </Paper>
  );
}

export default InputForm;
