import React from 'react';
import Box from '../../components/box/box';
import Grid from '../../components/grid/grid';
import Typography, {
  TypographyVariant,
} from '../../components/typography/typography';
import Icon, { IconType } from '../../components/icons/icon';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/routes';
import './footer.scss';

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigation = (route: string) => {
    navigate('..' + route);
  };

  return (
    <Grid
      item
      xs={12}
      style={{
        flex: 0,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        padding: '0 32px',
      }}
      className="bg-dark-primary border-top-light"
    >
      {/* Animated background glow */}
      <div
        className="animated-glow"
        style={{ position: 'absolute', inset: 0, opacity: 0.05 }}
      />

      <Box
        style={{
          flexGrow: 1,
          maxWidth: '1400px',
          width: '100%',
          padding: '64px 0 32px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Grid container spacing={4}>
          {/* Brand Section */}
          <Grid item xs={12} md={3}>
            <div
              className="flex-center"
              style={{
                marginBottom: '16px',
                cursor: 'pointer',
                justifyContent: 'flex-start',
              }}
              onClick={() => handleNavigation(routes.home)}
            >
              <div className="accent-badge" style={{ marginRight: '16px' }}>
                <Icon
                  iconType={IconType.HouseCircleCheck}
                  style={{ height: '24px', width: '24px' }}
                  className="text-primary"
                />
              </div>
              <Typography
                variant={TypographyVariant.H6}
                className="text-primary"
                style={{ fontWeight: 600 }}
              >
                PROP COST
              </Typography>
            </div>
            <Typography
              className="text-secondary"
              variant={TypographyVariant.Subtitle2}
              style={{ lineHeight: 1.6 }}
            >
              Your comprehensive solution for property cost analysis and
              investment planning.
            </Typography>
          </Grid>

          <Grid item xs={12} md={7} />

          {/* Quick Links */}
          <Grid item xs={12} md={2}>
            <Typography
              variant={TypographyVariant.Subtitle1}
              className="text-primary"
              style={{ marginBottom: '24px', fontWeight: 600 }}
            >
              QUICK LINKS
            </Typography>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}
            >
              {[
                { text: 'About', route: routes.about },
                { text: 'Contact', route: routes.contact },
                { text: 'Buyers Dashboard', route: routes.buyerDashboard },
                {
                  text: 'Investors Dashboard',
                  route: routes.investorDashboard,
                },
              ].map((link) => (
                <Typography
                  key={link.text}
                  className="text-secondary link-hover"
                  variant={TypographyVariant.Subtitle2}
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleNavigation(link.route)}
                >
                  {link.text}
                </Typography>
              ))}
            </div>
          </Grid>

          {/* Contact Info */}
          {/* <Grid item xs={12} md={2}>
            <Typography
              variant={TypographyVariant.Subtitle1}
              className="text-primary"
              style={{ marginBottom: '24px', fontWeight: 600 }}
            >
              CONTACT US
            </Typography>
            <Typography
              className="text-secondary"
              variant={TypographyVariant.Subtitle2}
              style={{ lineHeight: 1.6 }}
            >
              Email: contact@propcost.com
            </Typography>
          </Grid> */}

          {/* Copyright */}
          <Grid item xs={12}>
            <div style={{ padding: '16px', marginTop: '48px' }}>
              <Typography
                className="text-secondary"
                style={{
                  textAlign: 'center',
                  fontSize: '0.875rem',
                }}
              >
                © {new Date().getFullYear()} PROP COST. All rights reserved.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default Footer;
