import React, { CSSProperties, useMemo } from 'react';
import {
  LineChart as ReLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Label,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import Typography, { TypographyVariant } from '../typography/typography';
import { colors } from '../../Theme';

interface LineChartProps {
  xLabel?: string;
  yLabel?: string;
  title?: string;
  lines: LineProps[];
  style?: CSSProperties;
  labelPrefix?: string;
  xLabelInterval?: number;
  className?: string;
}

export interface LineProps {
  label: string;
  key: string;
  color: string;
  coordinates: CoordinateProps[];
}

export interface CoordinateProps {
  x: number;
  y: number;
}

interface Coordinate {
  [key: string]: number;
  x: number;
}

function LineChart(props: LineChartProps) {
  const smoothedData: Coordinate[] = useMemo(() => {
    const coordinates: Coordinate[] = [];

    for (const line of props.lines) {
      const key = line.key;

      for (const coordinate of line.coordinates) {
        const x = coordinate.x;
        const y = coordinate.y;

        const smoothedCoordinate = coordinates.find((c) => c.x === x);
        const index = coordinates.findIndex((c) => c.x === x);
        if (smoothedCoordinate === undefined) {
          coordinates.push({ x, [key]: y });
        } else {
          coordinates[index][key] = y;
        }
      }
    }

    return coordinates;
  }, [props]);

  const getYAxisTickLabel = (value: number): string => {
    if (Math.abs(value) >= 1000000) {
      return `$${(value / 1000000).toFixed(0)}M`;
    } else if (Math.abs(value) >= 1000) {
      return `$${(value / 1000).toFixed(0)}K`;
    } else {
      return `$${value}`;
    }
  };

  return (
    <div
      style={{ height: '100%', width: '100%', position: 'relative' }}
      className={props.className}
    >
      <ResponsiveContainer style={props.style}>
        <ReLineChart
          data={smoothedData}
          margin={{
            top: 0,
            right: 20,
            left: 20,
            bottom: 16,
          }}
        >
          <XAxis
            dataKey="x"
            interval={props.xLabelInterval}
            axisLine={{ stroke: colors.Secondary }}
            tickLine={{ stroke: colors.Secondary }}
            tick={{
              fill: colors.Secondary,
            }}
          >
            <Label
              value={props.xLabel}
              position="bottom"
              fill={colors.Secondary}
            />
          </XAxis>
          <YAxis
            tickFormatter={(value) => getYAxisTickLabel(value)}
            axisLine={{ stroke: colors.Secondary }}
            tickLine={{ stroke: colors.Secondary }}
            tick={{
              fill: colors.Secondary,
            }}
          >
            <Label
              value={props.yLabel}
              position="top"
              offset={15}
              angle={0}
              fill={colors.Secondary}
            />
          </YAxis>
          <CartesianGrid vertical={false} strokeDasharray="1" />
          <Tooltip
            labelFormatter={(value) => `Year ${value}`}
            formatter={(value) => [
              `${props.labelPrefix ?? '$'}${value.toLocaleString()}`,
              '',
            ]}
            separator=""
          />
          <Legend
            align="right"
            verticalAlign="top"
            layout="horizontal"
            height={36}
          />
          {props.lines.map((line) => (
            <Line
              key={line.key}
              name={line.label}
              dataKey={line.key}
              type="monotone"
              stroke={line.color}
              dot={false}
              label={line.label}
            />
          ))}
        </ReLineChart>
      </ResponsiveContainer>
      <Typography
        variant={TypographyVariant.H6}
        style={{ position: 'absolute', top: 0, left: 0, paddingLeft: '72px' }}
        className="light-text-color"
      >
        {props.title}
      </Typography>
    </div>
  );
}

export default LineChart;
