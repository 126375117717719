import React, { useMemo } from 'react';
import LineChart, {
  CoordinateProps,
} from '../../../components/line-chart/line-chart';
import { EstimateOutput } from '../../../api/models/EstimateOutput';
import { ChartGreen, ChartRed } from '../../../utils/constants/color';
import Box from '../../../components/box/box';
import ChartHeader from './chart-header';
import { Grid } from '@mui/material';
import Table, { RowProps } from '../../../components/table/table';

interface YearlyExpenseGainChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
}

function YearlyExpenseGainChart({
  estimateOutput,
  height,
}: YearlyExpenseGainChartProps) {
  const [expenseRows, monthlyExpenseLine] = useMemo(() => {
    const coords: CoordinateProps[] = [];
    const monthlyCoords: CoordinateProps[] = [];
    const tableRows: RowProps[] = [];

    coords.push({ x: 0, y: 0 });
    monthlyCoords.push({
      x: 0,
      y: 0,
    });

    for (const year of estimateOutput.years) {
      if (year.year === 0) {
        continue;
      }

      coords.push({ x: year.year, y: year.yearSummary.lossesTotal });
      monthlyCoords.push({
        x: year.year,
        y: Number((year.yearSummary.lossesTotal / 12).toFixed(2)),
      });

      tableRows.push({
        cells: [
          year.year.toString(),
          `$${Math.round(year.yearSummary.lossesTotal / 12).toLocaleString()}`,
          `$${Math.round(year.yearSummary.lossesTotal).toLocaleString()}`,
        ],
      });
    }

    return [
      tableRows,
      {
        label: 'Monthly Expense',
        key: 'monthlyExpenses',
        color: ChartRed,
        coordinates: monthlyCoords,
      },
    ];
  }, [estimateOutput]);

  return (
    <Box
      style={{
        height: `calc(${height}px - 1rem)`,
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
      }}
    >
      <ChartHeader
        title="Cost per Month"
        subtitle="The monthly cost of home ownership by year. You will need this much per month to pay for things such as home insurance, repairs, utilities, etc. See the cost breakdown next to see a detailed breakdown of these costs."
        className="text-input-primary"
      />

      <Grid container style={{ flexGrow: 1, overflow: 'auto' }}>
        {/* chart */}
        <Grid item xs={8} style={{ height: '100%', padding: '3rem' }}>
          <LineChart
            lines={[monthlyExpenseLine]}
            xLabel="Year"
            yLabel="$/month"
            className="chart-dark-theme"
          />
        </Grid>

        {/* table */}
        <Grid
          item
          xs={4}
          style={{
            paddingBottom: '1rem',
            height: 'calc(100% - 1rem)', // TODO: fix this
            overflow: 'auto',
          }}
        >
          <Table
            headers={['Year', 'Per Month', 'Year Total']}
            rows={expenseRows}
            className="table-dark-theme"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default YearlyExpenseGainChart;
