import React, { CSSProperties } from 'react';
import AddHomeIcon from '@mui/icons-material/AddHome';
import RefreshIcon from '@mui/icons-material/Refresh';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BalanceIcon from '@mui/icons-material/Balance';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { FaHouseCircleCheck } from 'react-icons/fa6';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MenuIcon from '@mui/icons-material/Menu';

interface IconProps {
  iconType: IconType;
  style?: CSSProperties;
  className?: string;
}

export enum IconType {
  AddHome = 'addHome',
  Refresh = 'refresh',
  Schedule = 'schedule',
  Calendar = 'calendar',
  Chart = 'chart',
  Balance = 'balance',
  TrendingDown = 'trendingDown',
  ExpandMore = 'expandMore',
  ExpandLess = 'expandLess',
  UnfoldLess = 'unfoldLess',
  UnfoldMore = 'unfoldMore',
  Info = 'info',
  UpArrow = 'upArrow',
  HouseCircleCheck = 'houseCircleCheck',
  RequestQuoteIcon = 'requestQuoteIcon',
  CreditScoreIcon = 'creditScoreIcon',
  Menu = 'menu',
}

function Icon(props: IconProps) {
  switch (props.iconType) {
    case IconType.AddHome:
      return <AddHomeIcon style={props.style} className={props.className} />;
    case IconType.Refresh:
      return <RefreshIcon style={props.style} className={props.className} />;
    case IconType.Schedule:
      return <ScheduleIcon style={props.style} className={props.className} />;
    case IconType.Calendar:
      return (
        <CalendarMonthIcon style={props.style} className={props.className} />
      );
    case IconType.Chart:
      return <ShowChartIcon style={props.style} className={props.className} />;
    case IconType.Balance:
      return <BalanceIcon style={props.style} className={props.className} />;
    case IconType.TrendingDown:
      return (
        <TrendingDownIcon style={props.style} className={props.className} />
      );
    case IconType.ExpandMore:
      return <ExpandMoreIcon style={props.style} className={props.className} />;
    case IconType.ExpandLess:
      return <ExpandLessIcon style={props.style} className={props.className} />;
    case IconType.UnfoldLess:
      return <UnfoldLessIcon style={props.style} className={props.className} />;
    case IconType.UnfoldMore:
      return <UnfoldMoreIcon style={props.style} className={props.className} />;
    case IconType.Info:
      return (
        <InfoOutlinedIcon style={props.style} className={props.className} />
      );
    case IconType.UpArrow:
      return <CallMadeIcon style={props.style} className={props.className} />;
    case IconType.HouseCircleCheck:
      return (
        <FaHouseCircleCheck style={props.style} className={props.className} />
      );
    case IconType.RequestQuoteIcon:
      return (
        <RequestQuoteIcon style={props.style} className={props.className} />
      );
    case IconType.CreditScoreIcon:
      return (
        <CreditScoreIcon style={props.style} className={props.className} />
      );
    case IconType.Menu:
      return <MenuIcon style={props.style} className={props.className} />;
    default:
      return null;
  }
}

export default Icon;
