import React, { CSSProperties } from 'react';
import { AppBar as MuiAppBar } from '@mui/material';

interface AppBarProps {
  children: React.ReactNode;
  position?: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative';
  className?: string;
  style?: CSSProperties;
}

function AppBar(props: AppBarProps) {
  const combinedClassName = `app-bar-override ${props.className || ''}`;
  return (
    <MuiAppBar {...props} className={combinedClassName}>
      {props.children}
    </MuiAppBar>
  );
}

export default AppBar;
