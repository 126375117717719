import { Table as MuiTable, TableHead } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import './table.scss';

export interface CellConfig {
  value: string;
  style?: React.CSSProperties;
}

export interface RowProps {
  cells: (string | CellConfig)[];
}

interface TableProps {
  headers: string[];
  rows: RowProps[];
  className?: string;
}

function Table({ headers, rows, className }: TableProps) {
  const renderCell = (cell: string | CellConfig) => {
    if (typeof cell === 'string') {
      return cell;
    }
    return <span style={cell.style}>{cell.value}</span>;
  };

  return (
    <TableContainer
      sx={{ flexGrow: 1, border: 'none', height: '100%' }}
      className={`${className}`}
    >
      <MuiTable stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell key={index} align={index === 0 ? 'left' : 'right'}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {row.cells.map((cell, index) => (
                <TableCell key={index} align={index === 0 ? 'left' : 'right'}>
                  {renderCell(cell)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </TableContainer>
  );
}

export default Table;
