import React, { useEffect, useRef, useState } from 'react';
import TopNavBar from '../../features/top-nav-bar/top-nav-bar';
import Dashboard, {
  DashboardViewType,
} from '../../features/dashboard/dashboard';

function BuyerDashboard() {
  const topNavBarRef = useRef(null);
  const [topNavBarHeight, setTopNavBarHeight] = useState(0);
  const [isNarrowScreen, setIsNarrowScreen] = useState(false);

  // get height of top nav bar
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    if (topNavBarRef.current) {
      setTopNavBarHeight((topNavBarRef.current as HTMLElement).offsetHeight);
    }
  }, []);

  // Check screen width on mount and resize
  useEffect(() => {
    const checkScreenWidth = () => {
      setIsNarrowScreen(window.innerWidth < 768); // Show message below 768px
    };

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);

    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);

  const height = `calc(100vh - ${topNavBarHeight}px)`;

  return (
    <div
      style={{ height: '100vh', width: '100vw' }}
      className="bg-gradient-hero"
    >
      <TopNavBar ref={topNavBarRef} setMaxWidth={true} />
      {isNarrowScreen ? (
        <div
          className="flex items-center justify-center p-4"
          style={{ height }}
        >
          <div
            className="glass-panel p-8 text-center max-w-md"
            style={{ padding: '16px', margin: '16px 16px' }}
          >
            <h2 className="text-primary text-xl font-semibold mb-3">
              Screen Too Small
            </h2>
            <p className="text-secondary">
              We currently only support desktop view. Please view the dashboard
              on a larger screen (minimum width: 768px) for the best experience.
            </p>
          </div>
        </div>
      ) : (
        <Dashboard height={height} viewType={DashboardViewType.Buyer} />
      )}
    </div>
  );
}

export default BuyerDashboard;
