import React, { useRef, useState, useEffect } from 'react';
import TopNavBar from '../../features/top-nav-bar/top-nav-bar';
import Grid from '../../components/grid/grid';
import Typography, {
  TypographyVariant,
} from '../../components/typography/typography';
import Button from '../../components/button/button';
import TextField from '@mui/material/TextField';
import Footer from '../../features/footer/footer';

function Contact() {
  const topNavBarRef = useRef(null);
  const [topNavBarHeight, setTopNavBarHeight] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    if (topNavBarRef.current) {
      setTopNavBarHeight((topNavBarRef.current as HTMLElement).offsetHeight);
    }
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        width: '100vw',
        overflowX: 'hidden',
        background: '#0a0c1b',
      }}
    >
      <TopNavBar ref={topNavBarRef} setMaxWidth={true} />

      {/* Hero Section */}
      <Grid
        container
        className="bg-gradient-hero section-padding-small"
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {/* Animated background glow */}
        <div
          className="animated-glow"
          style={{ position: 'absolute', inset: 0 }}
        />

        {/* Content wrapper */}
        <Grid container className="content-max-width">
          <Grid
            item
            xs={12}
            md={6}
            style={{
              position: 'relative',
              zIndex: 1,
              margin: '0 auto',
            }}
          >
            {/* Section Badge */}
            <div className="accent-badge" style={{ marginBottom: '24px' }}>
              <Typography
                className="text-primary"
                style={{ fontSize: '0.875rem' }}
              >
                Get in Touch
              </Typography>
            </div>

            <Typography
              variant={TypographyVariant.H2}
              className="hero-title"
              style={{ marginBottom: '24px' }}
            >
              Contact Us
            </Typography>

            <Typography
              className="text-secondary"
              style={{ marginBottom: '48px', lineHeight: 1.6 }}
            >
              We want your feedback! How can we make Prop Cost better? Fill out
              the form below and we'll get back to you as soon as possible.
            </Typography>

            <div className="glass-panel" style={{ padding: '32px' }}>
              {/* <form onSubmit={handleSubmit}> */}
              <form action="https://api.web3forms.com/submit" method="POST">
                <input
                  type="hidden"
                  name="access_key"
                  value="fb6baaad-d9af-45b5-bc85-2b03edcfd796"
                ></input>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                  required
                  className="mui-dark-input"
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                  className="mui-dark-input"
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  margin="normal"
                  required
                  multiline
                  rows={4}
                  className="mui-dark-input"
                />
                <Button
                  type="submit"
                  className="hero-button primary"
                  style={{ marginTop: '24px' }}
                >
                  Send Message
                </Button>
              </form>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
}

export default Contact;
