import React, { useMemo, useState } from 'react';
import Select, { SelectOptionProps } from '../../../components/select/select';
import Box from '../../../components/box/box';
import { EstimateOutput, Year } from '../../../api/models/EstimateOutput';
import { addSpacesToCamelCaseString } from '../../../utils/string-formatters';
import { getColors } from '../../../utils/constants/color';
import Grid from '../../../components/grid/grid';
import { IconType } from '../../../components/icons/icon';
import DataCard from '../../../components/data-card/data-card';
import ChartHeader from './chart-header';
import SankeyChart from '../../../components/sankey-chart/sankey-chart';
import {
  SankeyNode,
  SankeyLink,
} from '../../../components/sankey-chart/sankey-chart';
import Switch from '../../../components/switch/switch';

interface GainSankeyChartProps {
  estimateOutput: EstimateOutput;
  height?: number;
}

function GainSankeyChart({ estimateOutput, height }: GainSankeyChartProps) {
  const [selectedYearNum, setSelectedYearNum] = useState<number>(1);
  const [isMonthly, setIsMonthly] = useState(false);

  // dropdown options
  const yearSelectOptions: SelectOptionProps[] = useMemo(() => {
    const years: SelectOptionProps[] = [];

    for (const year of estimateOutput.years) {
      years.push({ label: year.year.toString(), value: year.year });
    }

    return years;
  }, [estimateOutput]);

  // handle year change
  const handleYearChange = (yearString: string) => {
    const yearNum = parseInt(yearString);
    setSelectedYearNum(yearNum);
  };

  const selectedYear: Year | undefined = useMemo(() => {
    return estimateOutput.years.find((y) => y.year === selectedYearNum);
  }, [estimateOutput, selectedYearNum]);

  // Convert gains to Sankey data
  const sankeyData = useMemo(() => {
    if (!selectedYear) {
      return { nodes: [], links: [] };
    }

    const nodes: SankeyNode[] = [];
    const links: SankeyLink[] = [];
    const colors = getColors(selectedYear.gains.length + 1);

    // Calculate divisor based on monthly/yearly view
    const divisor = isMonthly ? 12 : 1;

    // Add total node
    nodes.push({
      name: `Total Gains`,
      value: selectedYear.yearSummary.gainsTotal / divisor,
      fill: colors[0],
    });

    // Add gain nodes and links
    selectedYear.gains
      .filter((gain) => gain.amount > 0)
      .sort((a, b) => b.amount - a.amount)
      .forEach((gain, index) => {
        nodes.push({
          name: addSpacesToCamelCaseString(gain.subject),
          value: gain.amount / divisor,
          fill: colors[index + 1],
        });

        links.push({
          source: 0,
          target: index + 1,
          value: gain.amount / divisor,
        });
      });

    return { nodes, links };
  }, [selectedYear, isMonthly]);

  // render
  return (
    <div
      style={{
        padding: '1rem',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: `calc(${height}px - 1rem)`,
          width: '100%',
        }}
      >
        <ChartHeader
          title="Gain Breakdown"
          subtitle="A breakdown of the gains for each year. Gains include vested equity, appreciation, and tax savings from mortgage."
          className="text-input-primary"
        />

        {selectedYear !== undefined && (
          <Grid
            container
            style={{ flexGrow: 1, overflow: 'hidden', paddingTop: '1rem' }}
          >
            <Grid item xs={1}></Grid>

            {/* sankey chart */}
            <Grid item xs={9} style={{ padding: '1rem', height: '100%' }}>
              <SankeyChart
                data={sankeyData}
                height="100%"
                style={{ minHeight: '400px' }}
              />
            </Grid>

            {/* legend */}
            <Grid
              item
              xs={2}
              style={{
                overflow: 'hidden',
                height: '100%',
                paddingBottom: '1rem',
                paddingRight: '1rem',
                paddingLeft: '1rem',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Switch
                checked={isMonthly}
                onChange={(e) => setIsMonthly(e.target.checked)}
                leftLabel="Yearly"
                rightLabel="Monthly"
                style={{ justifyContent: 'center' }}
                className="switch-dark-theme"
              />

              <Select
                value={selectedYear.year.toString()}
                onChange={handleYearChange}
                options={yearSelectOptions}
                label="Year"
                style={{ width: '100%', marginTop: '0.5rem' }}
                className="select-dark-theme"
              />

              {selectedYearNum !== 0 && (
                <DataCard
                  title="Per Month"
                  body={`$${Math.round(
                    selectedYear.yearSummary.gainsTotal / 12
                  ).toLocaleString()}`}
                  iconVariant={IconType.Calendar}
                  style={{ marginTop: '8px' }}
                  infoTitle="Per Month"
                  infoMessage="The average gains per month for the year."
                />
              )}

              <DataCard
                title={`Year ${selectedYear.year} Total`}
                body={`$${Math.round(
                  selectedYear.yearSummary.gainsTotal
                ).toLocaleString()}`}
                iconVariant={IconType.Schedule}
                style={{ marginTop: '8px' }}
                infoTitle="Year Total"
                infoMessage="The total gains for the year."
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </div>
  );
}

export default GainSankeyChart;
