import React from 'react';
import Typography, {
  TypographyVariant,
} from '../../../components/typography/typography';
import Divider from '../../../components/divider/divider';

interface ChartHeaderProps {
  title: string;
  subtitle?: string;
  className?: string;
}

function ChartHeader({ title, subtitle, className }: ChartHeaderProps) {
  return (
    <div className={className}>
      <Typography variant={TypographyVariant.H5}>{title}</Typography>

      {subtitle && (
        <Typography variant={TypographyVariant.Subtitle1}>
          {subtitle}
        </Typography>
      )}

      <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
    </div>
  );
}

export default ChartHeader;
